import React, { Component, Fragment } from "react";
import { View, ScrollView } from "react-native-web";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
//
import Colors from "../../commons/Colors";
//
import { setSnackbar, setTimer } from "../../actions/appActions";
import { toMoney } from "../../handlers/money";
import { DeleteHandler, GetHandler, PostHandler } from "../../handlers";
import {
  Avatar,
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  Icon,
  IconButton,
  InputLabel,
  Portal,
} from "@material-ui/core";
import { ArrowLeft, ArrowLeftSharp, CheckBox, Star } from "@material-ui/icons";
import ProfissionalItems from "./profissionalItems";
import moment from "moment";
import { Alert, Dimensions } from "react-native";
import { Link } from "react-router-dom";

class SearchList extends Component {
  state = {
    dataStart: moment(),
    profile: undefined,
    doctorData: undefined,
    showOrderpanel: false,
    scheduleData: {
      pacienteId: undefined,
      perfilId: undefined,
      doutorId: this.props.dados?.id,
      horario: undefined,
      data: moment().format("yyyy-MM-DD"),
      tipoAtendimento: this.props?.location?.tipoAtendimento ?? "video",
      //linkPagSeguro: true,
      linkAsaas:true,
      formaPagAsaas: ""
    },
    orderBy: [
      { column: "pontuacao.desc", label: "Avaliação", checked: false },
      {
        column: "totalConsultasAtendidas.desc",
        label: "Número de consultas",
        checked: false,
      },
      { column: "precoConsulta.desc", label: "Maior Preço", checked: false },
      { column: "precoConsulta.asc", label: "Menor Preço", checked: false },
      { column: "pontuacao.desc", label: "Tempo de trabalho", checked: false },
    ],
    results: [],
    pageOptions: undefined,
    refreshing: false,
  };

  async setOrder(item) {
    let orderBy = this.state.orderBy;
    orderBy.find((order) => order.label == item.label).checked = !item.checked;
    this.setState({
      orderBy,
      showOrderpanel: false,
    });
    await this.props.getData(undefined, orderBy);
  }

  sendSchedule = async (item: any) => {
    let authData = JSON.parse(localStorage.getItem("user_info"));

    let scheduleData = this.state.scheduleData;
    scheduleData.doutorId = item?.id ?? item?.doutorId;
    scheduleData.perfilId = item?.perfilId;
    scheduleData.pacienteId = authData?.id;
    scheduleData.paciente = {
      nome: authData?.nome ? `${authData?.nome} ${authData?.sobrenome}` : "",
      cpf: authData?.cpf ?? "",
      email: authData?.email ?? ""
    }

    this.setState({ ...this.state, reloading: true });
    if (scheduleData.horario === undefined) {
      alert(
        "Selecione um horário\n\né necessário escolher um horário disponível para agendar a consulta"
      );
      this.setState({ ...this.state, reloading: false });
      return false;
    }
    /*if (!this.state.terms) {
      Alert.alert(
        "Termos e condições",
        " é necessário concordar com os termos e condições de agendamento"
      );
      this.setState({ ...this.state, reloading: false });
      return false;
    }*/
    this.setState({ ...this.state, reloading: true });
    if (
      !scheduleData.pacienteId ||
      !scheduleData.perfilId ||
      !scheduleData.doutorId
    ) {
      alert("Login Obrigatório\n\nFaça login para continuar!");
      /*this.props.navigation.navigate("profile", {
        refresh: true,
        schedule: scheduleData,
      });*/
    } else {
      if((authData?.cpf ?? "").length <= 10){
        this.props.setProfileDialog({open: true, type: 'profile'});
        return false;
      }
      const scheduling = await PostHandler(
        `consultas`,
        scheduleData,
        undefined
      );

      if (scheduling?.data?.success) {
        alert(
          "Atenção\n\nO seu horário foi reservado, e será confirmado após efetuar o pagamento !"
        );
        if (
          confirm(
            "Efetuar Pagamento\n\nDeseja efetuar o pagamento da consulta agora?"
          ) == true
        ) {
          window
            .open(scheduling?.data?.data?.linkCobranca?.linkCobranca, "_blank")
            .focus();
          return false;
        }
        window.location.href = "/history";
      } else {
        //console.log('AGAGAG:', scheduling)
        alert(
          "Problema ao agendar " +
            (scheduling?.data?.message ??
              "Houve um problema ao tentar efetuar o agendamento")
        );
      }
    }
    this.setState({ ...this.state, reloading: false });
  };

  render() {
    const status = [
      {
        cod: "noPay",
        color: "#d26900",
        name: "Pendente",
      },
      {
        cod: "naoConfirmado",
        name: "Não Confirmado",
        icon: "calendar-blank",
        color: "#987b01",
        background: "#faf4d5",
        material: true,
      },
      {
        cod: "confirmado",
        name: "Confirmado",
        icon: "calendar-check",
        color: "#04bc17",
        background: "#d6fcd9",
        material: true,
      },
      {
        cod: null,
        name: "Agendado",
        icon: "calendar-blank",
        color: "#04bc17",
        background: "#faf4d5",
        material: true,
      },
      {
        cod: "jaSeEncontra",
        name: "Aguardando",
        icon: "timer",
        color: "#008085",
        background: "#d1f8f5",
      },
      {
        cod: "estaSendoAtendido",
        name: "Em Atendimento",
        icon: "person",
        color: "#2558c9",
        background: "#d1ddf8",
      },
      {
        cod: "jaFoiAtendido",
        name: "Atendido",
        icon: "checkmark-circle-outline",
        color: "#8b8b8b",
        background: "#efefef",
      },
      {
        cod: "faltou",
        name: "Não Compareceu",
        icon: "close-circle-outline",
        color: "#a60606",
        background: "#f8d1d1",
      },
      {
        cod: "desmarcado",
        name: "Desmarcado",
        icon: "close-circle-outline",
        color: "#a60606",
        background: "#f8d1d1",
      },
    ];

    return (
      <View>
        {(this.props.data ?? []).length > 0 ? (
          <View style={{ marginBottom: 60, zIndex: 889 }}>
            <ScrollView>
              {(this.props.data ?? []).map((item, key) => {
                const today = moment();
                const created = moment(item.dataCad ?? undefined);

                const dateData = {
                  years: Math.abs(
                    parseInt(today.format("yyyy")) -
                      parseInt(created.format("yyyy"))
                  ),
                  months: Math.abs(
                    parseInt(today.format("MM")) -
                      parseInt(created.format("MM"))
                  ),
                  days: Math.abs(
                    parseInt(moment().format("DD")) -
                      parseInt(
                        moment(moment().subtract(1, "month"))
                          .endOf("month")
                          .format("MM")
                      )
                  ),
                };

                return (
                  <View
                    style={{ marginBottom: 10 }}
                    key={`history-search-list-${key}`}
                  >
                    <Card>
                      <CardContent>
                        <View
                          style={{
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                        >
                          <View
                            style={{
                              flexDirection: "column",
                              alignItems: "center",
                              justifyContent: "flex-start",
                            }}
                          >
                            <View
                              style={{ width: "100%", alignItems: "center" }}
                            >
                              <img
                                alt={item?.doutor?.nome}
                                src={
                                  item?.doutor?.urlFoto ??
                                  require("../../../assets/empty_image.png")
                                }
                                style={{
                                  flex: 1,
                                  width: "100%",
                                  height: "100%",
                                  maxWidth: 100,
                                  borderRadius: "50%",
                                }}
                              />
                              <Link
                                style={{ textDecoration: "none" }}
                                to={{
                                  pathname: `/doutor`,
                                  state: item?.doutor,
                                }}
                              >
                                <Button
                                  style={{ marginTop: 10 }}
                                  variant={"contained"}
                                  color={"primary"}
                                >
                                  Ver Perfil
                                </Button>
                              </Link>
                            </View>
                          </View>
                          <View
                            style={{
                              marginStart: 36,
                              marginBottom: 10,
                              flex: 1,
                              flexDirection: "row",
                              justifyContent: "space-start",
                            }}
                          >
                            <View style={{ marginEnd: 15 }}>
                              <InputLabel
                                style={{
                                  fontSize: 18,
                                  fontWeight: "bold",
                                  marginBottom: 10,
                                  marginTop: 25,
                                }}
                              >
                                {item?.doutor.nomeDoutor}
                              </InputLabel>

                              <InputLabel style={{ marginBottom: 10 }}>
                                Data:{" "}
                                <b>
                                  {moment(item.dataConsulta).format(
                                    "DD/MM/yyyy"
                                  )}
                                </b>
                              </InputLabel>
                              <InputLabel style={{ marginBottom: 10 }}>
                                Horário :{" "}
                                <b>
                                  {moment(
                                    item.dataConsulta + " " + item.horaConsulta
                                  ).format("HH:mm")}
                                </b>
                              </InputLabel>
                              {(item.procedimentos ?? []).length > 0 ? (
                                <InputLabel
                                  style={{
                                    flex: 1,
                                    width: 210,
                                    flexWrap: "wrap",
                                    marginBottom: 10,
                                  }}
                                >
                                  <b>
                                    {(item.procedimentos ?? [])
                                      .map((proc) => proc.nome)
                                      .join(", ")}
                                  </b>
                                </InputLabel>
                              ) : undefined}
                              {item?.desmarcadoPor ||
                                (!item.pago && (
                                  <InputLabel
                                    style={{
                                      flex: 1,
                                      width: 210,
                                      flexWrap: "wrap",
                                      marginBottom: 10,
                                    }}
                                  >
                                    Status{" "}
                                    <b>
                                      {!item.pago
                                        ? " Falta de Pagamento"
                                        : ` ${item?.desmarcadoPor} ${item?.razaoDesmarcacao} `}
                                    </b>
                                  </InputLabel>
                                ))}
                              <InputLabel style={{ marginBottom: 10 }}>
                                Preço :{" "}
                                <b>
                                  {(item?.precoConsulta ?? "0.00").replace(
                                    ".",
                                    ","
                                  )}
                                </b>
                              </InputLabel>
                            </View>
                            {1 == 2 && (
                              <View>
                                <View
                                  style={{
                                    flexDirection: "row",
                                    flexWrap: "wrap",
                                    justifyContent: "flex-between",
                                    marginTop: 15,
                                  }}
                                >
                                  <View
                                    style={{ marginBottom: 15, marginEnd: 25 }}
                                  >
                                    <InputLabel
                                      style={{
                                        color: Colors.secondary,
                                        fontSize: 18,
                                      }}
                                    >
                                      Tempo de Consulta
                                    </InputLabel>
                                    <InputLabel>
                                      <span style={{ color: Colors.secondary }}>
                                        {" "}
                                        •{" "}
                                      </span>
                                      {this.props?.location?.tipoAtendimento == "video" ? item?.procedimentoPadrao?.duracaoVideoConsulta ?? 50 :item?.procedimentoPadrao?.duracao ?? 50} minutos 
                                    </InputLabel>
                                  </View>
                                  <View
                                    style={{ marginBottom: 15, marginEnd: 25 }}
                                  >
                                    <InputLabel
                                      style={{
                                        color: Colors.secondary,
                                        fontSize: 18,
                                      }}
                                    >
                                      Formação Acadêmica
                                    </InputLabel>
                                    {(item?.formacoes ?? []).map(
                                      (form, ind) => {
                                        return (
                                          <View key={`formmacao-${ind}`}>
                                            <InputLabel>
                                              {" "}
                                              <span
                                                style={{
                                                  color: Colors.secondary,
                                                }}
                                              >
                                                {" "}
                                                •{" "}
                                              </span>{" "}
                                              {form?.nomeFormacao}{" "}
                                            </InputLabel>
                                            <InputLabel>
                                              {" "}
                                              <span
                                                style={{
                                                  color: Colors.secondary,
                                                }}
                                              >
                                                {" "}
                                                •{" "}
                                              </span>{" "}
                                              Periodo:{" "}
                                              {form?.periodoDe
                                                ? `(${moment(
                                                    form?.periodoDe ?? undefined
                                                  ).format(
                                                    "MM/yyyy"
                                                  )} - ${moment(
                                                    form?.periodoAte ??
                                                      undefined
                                                  ).format("MM/yyyy")})`
                                                : "Não Informado"}
                                            </InputLabel>
                                            <InputLabel>
                                              {" "}
                                              <span
                                                style={{
                                                  color: Colors.secondary,
                                                }}
                                              >
                                                {" "}
                                                •{" "}
                                              </span>{" "}
                                              {form?.instituicaoEnsino}
                                            </InputLabel>
                                          </View>
                                        );
                                      }
                                    )}
                                  </View>

                                  <View
                                    style={{ marginBottom: 15, marginEnd: 25 }}
                                  >
                                    <InputLabel
                                      style={{
                                        color: Colors.secondary,
                                        fontSize: 18,
                                      }}
                                    >
                                      Atende
                                    </InputLabel>
                                    {(item?.gruposAtendimento ?? []).map(
                                      (at, ind) => {
                                        return (
                                          <InputLabel key={`ga-${ind}`}>
                                            {" "}
                                            <span
                                              style={{
                                                color: Colors.secondary,
                                              }}
                                            >
                                              {" "}
                                              •{" "}
                                            </span>{" "}
                                            {at.nome}
                                          </InputLabel>
                                        );
                                      }
                                    )}
                                  </View>

                                  <View
                                    style={{ marginBottom: 15, marginEnd: 25 }}
                                  >
                                    <InputLabel
                                      style={{
                                        color: Colors.secondary,
                                        fontSize: 18,
                                      }}
                                    >
                                      Idiomas
                                    </InputLabel>
                                    {(item?.idiomas ?? []).map((at, ind) => {
                                      return (
                                        <InputLabel key={`ga-${ind}`}>
                                          {" "}
                                          <span
                                            style={{ color: Colors.secondary }}
                                          >
                                            {" "}
                                            •{" "}
                                          </span>{" "}
                                          {at.nome}
                                        </InputLabel>
                                      );
                                    })}
                                  </View>
                                </View>
                              </View>
                            )}
                          </View>
                          <View>
                            <View
                              style={{
                                paddingBotom: 10,
                                paddingHorizontal: 0,
                                marginBottom: 20,
                              }}
                            >
                              <InputLabel
                                variant="outlined"
                                style={{
                                  marginBottom: 10,
                                  color: status.find(
                                    (s) =>
                                      s.cod ==
                                      (item.pago
                                        ? item.statusConsulta
                                        : "noPay")
                                  )?.color,
                                }}
                              >
                                {item.pago
                                  ? status.find(
                                      (s) => s.cod == item.statusConsulta
                                    )?.name
                                  : "Pendente"}
                              </InputLabel>
                            </View>
                            {item?.pago == true ||
                            item?.videoconferencia?.videoPago == 2 ? (
                              <Button
                                variant="contained"
                                style={{
                                  backgroundColor: status.find((s) =>
                                    s.cod == item.pago
                                      ? item.statusConsulta
                                      : "noPay"
                                  )?.color,
                                }}
                                onClick={() => {
                                  if (item?.videoconferencia?.link) {
                                    window
                                      .open(
                                        item?.videoconferencia?.link,
                                        "_blank"
                                      )
                                      .focus();
                                  } else {
                                    alert("Erro interno, fale com o suporte");
                                  }
                                }}
                              >
                                {"ENTRAR"}
                              </Button>
                            ) : (
                              <View>
                                <Button
                                  variant="contained"
                                  style={{
                                    backgroundColor: status.find((s) =>
                                      s.cod == item.pago
                                        ? item.statusConsulta
                                        : "noPay"
                                    )?.color,
                                  }}
                                  onClick={() => {
                                    console.log("ITEM: ", item);
                                    if (item?.linkCobranca?.linkCobranca) {
                                      window
                                        .open(item?.linkCobranca?.linkCobranca, "_blank")
                                        .focus();
                                    } else {
                                      alert("Erro interno, fale com o suporte");
                                    }
                                  }}
                                >
                                  {"Pagar"}
                                </Button>
                                <Button
                                  variant="contained"
                                  style={{
                                    marginTop: 10,
                                    backgroundColor: status.find(
                                      (s) => s.cod == "faltou"
                                    )?.color,
                                    color: "#ffffff",
                                  }}
                                  onClick={async () => {
                                    if (
                                      confirm(
                                        `Deseja realmente cancelar este agendamento ?`
                                      ) == true
                                    ) {
                                      // console.log('GET AG: ', `consultas/${item.id}`, {perfilId: this.props.session})
                                      const auth = JSON.parse(
                                        localStorage.getItem("user_info")
                                      );
                                      //return false;
                                      const del = await DeleteHandler(
                                        `consultas/${item.id}`,
                                        { perfilId: auth.perfilId },
                                        this.props.navigation
                                      );
                                      if (del?.success) {
                                        await this.props.getData();
                                        alert(
                                          `Sucesso!\nAgendamento cancelado.`
                                        );
                                      }
                                    }
                                  }}
                                >
                                  {"Cancelar"}
                                </Button>
                              </View>
                            )}
                          </View>
                        </View>
                      </CardContent>
                    </Card>
                  </View>
                );
                return (
                  <ProfissionalItems
                    profile={this.state.profile}
                    key={"profitem-" + key}
                    data={item}
                    setProfileData={(profile) => {
                      //console.log('PERFIL: ', profile);
                      this.setProfile(profile);
                    }}
                  />
                );
              })}
            </ScrollView>
            <View
              style={{
                flexDirection: "row",
                justifyContent: "center",
                padding: 5,
                margin: 5,
              }}
            >
              <View>
                {/*this.state.pageOptions?.page <
                  this.state.pageOptions.totalPages && (
                    <Button
                      mode={"InputLabel"}
                      onClick={() => {
                        console.log(this.state.pageOptions);
                        this.getData(this.state.pageOptions);
                      }}
                    >
                      Ver Mais
                    </Button>
                    )*/}
              </View>
            </View>
          </View>
        ) : (
          <View
            style={{
              flexDirection: "row",
              justifyContent: "center",
              padding: 5,
              margin: 5,
            }}
          >
            <View>
              <InputLabel
                style={{
                  fontSize: 14,
                  color: Colors.black,
                }}
              >
                {this.state?.refreshing
                  ? undefined
                  : "Não há Agendamentos para exibir."}
              </InputLabel>
            </View>
          </View>
        )}
      </View>
    );
  }
}
const mapStateToProps = (store) => ({
  session: store.authReducer.data,
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ setSnackbar, setTimer }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SearchList);

const styles = {
  container: {
    paddingTop: 30,
    borderRadius: 0,
    backgroundColor: Colors.primary,
    minHeight: "100%",
  },
  secView: {
    flex: 1,
    flexDirection: "row",
    alignItems: "flex-end",
    justifyContent: "flex-end",
    paddingRight: 15,
    maxHeight: 65,
    paddingBottom: 15,
    bottom: 0,
    backgroundColor: Colors.secondary,
    width: "100%",
  },
  logoContainer: {
    alignItems: "center",
    justifyContent: "center",
    padding: 30,
  },
  logo: {
    height: 150,
    width: 150,
    resizeMode: "contain",
  },
  inputDefaultStyle: {
    height: 40,
  },
}
