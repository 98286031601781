import React, { Component, Fragment } from "react";
import { View } from "react-native-web";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
//
import Colors from "../../commons/Colors";
//
import { setSnackbar, setTimer } from "../../actions/appActions";
import { toMoney } from "../../handlers/money";
import { GetHandler } from "../../handlers";
import SearchList from "./searchList";
import { withRouter, WithRouterProps } from "react-router-dom";
import moment from "moment";
import { ActivityIndicator, TouchableOpacity } from "react-native";
import { Button, IconButton, InputLabel } from "@material-ui/core";
import { ArrowLeftSharp } from "@material-ui/icons";

class History extends Component {
  state = {
    old: false,
    profile: undefined,
    data: [],
    doctorData: undefined,
    showOrderpanel: false,
    orderBy: [
      { column: "pontuacao.desc", label: "Avaliação", checked: false },
      {
        column: "totalConsultasAtendidas.desc",
        label: "Número de consultas",
        checked: false,
      },
      { column: "precoConsulta.desc", label: "Maior Preço", checked: false },
      { column: "precoConsulta.asc", label: "Menor Preço", checked: false },
      { column: "pontuacao.desc", label: "Tempo de trabalho", checked: false },
    ],
    results: [],
    pageOptions: undefined,
    refreshing: false,
  };

  getData1 = async (pageOptions = undefined, orderBy = []) => {
    try {
      this.setState({ ...this.state, refreshing: true });
      let filter = this.props?.filter ?? this.props.location.state;
      console.log("FILLLL: ", this.props.location.state);
      //
      filter.valorConsultaMax = toMoney(filter.valorConsultaMax, true).replace(
        ",",
        "."
      );
      filter.valorConsulta = toMoney(filter.valorConsulta, true).replace(
        ",",
        "."
      );
      filter.especialidade = filter.especialidade ?? "";
      filter.perPage = 5;
      filter.favoritoPacienteId = this.props.profile?.data?.id ?? "";
      let order = orderBy
        .filter((item) => item.checked)
        .map((item) => item.column)
        .join(",");
      filter.orderBy = order;

      filter.nomeFormacao = filter.nomeFormacao ?? [];

      if (pageOptions) {
        filter = { ...filter, page: parseInt(pageOptions.page) + 1 };
      }
      let request = await GetHandler(`doutores`, filter, this.props.navigation);
      if (
        request &&
        Array.isArray(request?.data?.results) &&
        request?.data?.results?.length >= 0
      ) {
        let results = request?.data?.results ?? [];
        if (pageOptions) {
          results = this.state.results.concat(results);
        }

        this.setState({
          results,
          pageOptions: {
            page: request?.data?.page,
            perPage: request?.data?.perPage,
            totalPages: request?.data?.totalPages,
          },
          refreshing: false,
        });
      } else if (request) {
        this.setState({ ...this.state, refreshing: false });
      }
    } catch (e) {
      console.error(e);
    }
    //this.setState({...this.state, refreshing: false});
  };

  async getData(params = undefined) {
    this.setState({
      ...this.state,
      refreshing: true,
      old: params?.old ? params?.old : false,
    });

    let inicio = moment().format("yyyy-MM-DD");
    let dataFim = moment().add(180, "month").format("yyyy-MM-DD hh:mm:ss");
    let horaInicio = moment().format('hh:mm');
    let horaFim = '';

    if (params?.old == true) {
      inicio = moment().subtract(180, "month").format("yyyy-MM-DD");
      dataFim = moment().format("yyyy-MM-DD hh:mm:ss");
      horaInicio = '';
      horaFim = moment().format('hh:mm');
    }

    try {
      const auth = JSON.parse(localStorage.getItem("user_info"));
      const request = await GetHandler(
        "consultas",
        {
          //showProcedimentos: true,
          buscaPaciente: "",
          pacienteId: auth.id,
          data: inicio,
          perPage: 9999,
          showProntuarios: true,
          orderBy: params?.old ? "dataConsulta.desc,horaConsulta.desc" : "dataConsulta.asc,horaConsulta.asc",
          perfilId: auth.perfilUsuarioId ?? auth.perfilId,
          dataFim,
          horaInicio,
          horaFim
        },
        this.props.navigation
      );
      if (
        request &&
        Array.isArray(request?.data?.results) &&
        request?.data?.results?.length >= 0
      ) {
        //console.log('AGENDAMENTOS', request?.data?.results);
        this.setState({
          data: request?.data?.results ?? [],
          refreshing: false,
        });
      } else if (request) {
        this.setState({ ...this.state, refreshing: false });
      }
      console.log('REFRESHING: ', this.state.refreshing)
      this.setState({ ...this.state, refreshing: false });
    } catch (e) {
      this.setState({ ...this.state, refreshing: false });
      console.log(e);
    }
  }

  async componentDidMount() {
    //console.log('FILTROS:: ', this.props.filter)
    await this.getData();
  }

  render() {
    return (
      <Fragment>
        <View style={{ flexDirection: "row", justifyContent: "center" }}>
          <View style={{ flexBasis: "80%" }}>
            <View
              style={{
                //backgroundColor: Colors.primary,
                padding: 15,
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <View>
                <IconButton
                  onClick={() => {
                    window.location.href = "/";
                  }}
                  color="primary"
                  aria-label="back"
                  component="label"
                >
                  <ArrowLeftSharp />
                </IconButton>
              </View>
              <View style={{ marginStart: 15 }}>
                <InputLabel style={{ color: Colors.primary }}>
                  Meus Agendamentos
                </InputLabel>
              </View>
            </View>

            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                width: 350,
                marginBottom: 10
              }}
            >
              <TouchableOpacity
                style={{
                  flexBasis: "45%",
                }}
                onPress={async () => {
                  await this.getData();
                }}
              >
                <Button
                  variant={this.state?.old == true ? "outilined" : "contained"}
                  color={"primary"}
                  style={{ textAlign: "center" }}
                >
                  Próximos
                </Button>
              </TouchableOpacity>
              <TouchableOpacity
                style={{
                  flexBasis: "45%",
                }}
                onPress={async () => {
                  await this.getData({ old: true });
                }}
              >
                <Button
                  variant={this.state?.old != true ? "outilined" : "contained"}
                  color={"primary"}
                  style={{ textAlign: "center" }}
                >
                  Anteriores
                </Button>
              </TouchableOpacity>
            </View>
            {this.state.refreshing == false ? 
            (<View>
              <SearchList
              location={this.props.location.state}
              data={this.state.data}
              getData={async () => {
                await this.getData();
              }}
            /> 
            </View>): (
            <View>
              <ActivityIndicator size={22} />
            </View>
              )}
          </View>
        </View>
      </Fragment>
    );
  }
}
const mapStateToProps = (store) => ({
  session: store.authReducer.data,
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ setSnackbar, setTimer }, dispatch);

export default withRouter(History);

const styles = {
  container: {
    paddingTop: 30,
    borderRadius: 0,
    backgroundColor: Colors.primary,
    minHeight: "100%",
  },
  secView: {
    flex: 1,
    flexDirection: "row",
    alignItems: "flex-end",
    justifyContent: "flex-end",
    paddingRight: 15,
    maxHeight: 65,
    paddingBottom: 15,
    bottom: 0,
    backgroundColor: Colors.secondary,
    width: "100%",
  },
  logoContainer: {
    alignItems: "center",
    justifyContent: "center",
    padding: 30,
  },
  logo: {
    height: 150,
    width: 150,
    resizeMode: "contain",
  },
  inputDefaultStyle: {
    height: 40,
  },
};
