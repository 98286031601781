export const BRANDING = {
    perfilId: 4786, // 4301 = dev,  4786 = prod
    Colors: {
        primary: '#104267',
        secondary: '#104267',
        tertiary: '#00d5ff',
        quaternary: '#86fce2',
        quinary: '#1d68b9',
        senary: '',
        white: '#FFFFFF',
        black: '#333333',
        grey: '#e7e7e7',
        red: '#f44336',
        border: '#e7e7e7'
    },
    titleHeader: "IBAC",
    filterLogo: {
        img: require("./assets/images/ibac/filter_logo.png") ?? "",
        width: 200
    },
    loginLogo: {
        img: require("./assets/images/ibac/logo_login.png") ?? "",
        height: 125
    },
    homeText: "O IBAC é o maior centro de psicologia comportamental da região centro-oeste, tanto como centro formador de psicólogos através de seus cursos de pós-graduação, como também enquanto prestador de serviços na área clínica."
}