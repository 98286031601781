import React from "react";
import Badge from "@material-ui/core/Badge";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { withRouter } from "react-router-dom";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { DataGrid, RowsProp, ColDef } from "@material-ui/data-grid";
import { DEFAULT_LOCALE_TEXT } from "../../providers/langs/datagrid";
import {
    TextField,
    Card,
    CardContent,
    Typography,
    CardActions,
    ListItemText,
    ListItem,
    List,
    IconButton,
} from "@material-ui/core";
import IndeterminateCheckBox from "@material-ui/icons/IndeterminateCheckBox";
import DeleteForever from "@material-ui/icons/DeleteForever";
import { TextInput } from "react-native";
import { BlockOutlined, CheckCircleOutline } from "@material-ui/icons";
import { TouchableOpacity } from "react-native-web";

export const StyledBadge = withStyles((theme) => ({
    badge: {
        right: -3,
        top: 13,
        border: `2px solid ${theme.palette.background.paper}`,
        padding: "0 4px",
    },
}))(Badge);

export const PrimaryButton = withStyles((theme) => ({
    button: {
        background: theme.palette.primary.main,
        right: -3,
        top: 13,
        fontSize: 64,
        border: `2px solid ${theme.palette.background.paper}`,
        padding: "0 4px",
    },
}))(Button);

export const CustomAutocomplete = (props) => {
    const [value, setValue] = React.useState(
        props.value
            ? props.values.find((item) => item.id == props.value)
            : undefined
    );
    const [inputValue, setInputValue] = React.useState("");
    const [defaultVal, setDefault] = React.useState(
        props.value
            ? props.values.find((item) => item.id == props.value)
            : undefined
    );

    function handleChange(e, newValue) {
        const { val, id } = e.target;
        let vl = { target: { id: props.id, value: newValue.id } };
        if (props.validate !== undefined) {
            if (props.validate(val)) {
                setError(false);
            } else {
                setError(true);
            }
        }
        props.onChange(newValue);
        setValue(newValue);
    }
    return (
        <Autocomplete
            key={`autocomplete-${props.id}`}
            id={props.id}
            disabled={props.disabled ?? false}
            name={props.name}
            value={value}
            inputValue={inputValue}
            onChange={handleChange}
            getOptionSelected={(option, value) => {
                return option.id == value.id;
            }}
            getOptionLabel={(option) => option[props.valueLabel]}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
            }}
            options={props.values}
            style={props.style}
            renderInput={(params) => (
                <TextField {...params} label={props.label} />
            )}
        />
    );
};

const MaskedDecimalInput = (props) => {
    const [value1, setValue] = React.useState(props.value ?? 0);
    const [error, setError] = React.useState(false);
    function getMoney(str) {
        return parseInt(str.replace(/[\D]+/g, ""));
    }
    function formatReal(int) {
        var tmp = int + "";
        tmp = tmp.replace(/([0-9]{2})$/g, ",$1");
        if (tmp.length > 6)
            tmp = tmp.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");
        if (tmp.length >= 9)
            tmp = tmp.replace(
                /([0-9]{3})\.([0-9]{3}),([0-9]{2}$)/g,
                ".$1.$2,$3"
            );
        return tmp;
    }
    function handleChange(e) {
        //const { value, id } = e.target;
        let val = e.target.value.length > 0 ? e.target.value : "0";
        if (val.length < 14) {
            props?.onChange(e) ?? undefined;
            setValue(formatReal(getMoney(val)));
        }
    }
    return (<TextField 
        placeholder={"Valor"}
        value={value1 ?? ""}
        label={props?.label} 
        onChange={handleChange}
        onBlur={handleChange}
        />)
    return (
        <TextField
            key={`input-${props?.id ?? Date.now.toString()}`}
            size="small"
            style={props?.style}
            required={props?.required ?? false}
            //disabled={props?.disabled ?? false}
            error={error}
            type={props?.type ?? "text"}
            //value={value1 ?? ""}
            helperText={
                error == true ? props?.helperText ?? "conteúdo inválido" : ""
            }
            id={props?.id}
            label={props?.label}
            //onChange={handleChange}
            //onBlur={handleChange}
        />
    );
};

export class DataGridCustom extends React.Component {
    state = {
        title: "",
        filter: [],
        data: [],
        products: []
    };

    async componentDidMount() {
       // console.log("DATAA: ", this.props.data);
    }

    render() {
        const authData = JSON.parse(localStorage.getItem("user"));
        const rows: RowsProp =
            this.props?.data !== undefined
                ? authData?.manager?.id
                    ? this.props?.data
                    : this.props?.data.filter((item) => parseInt(item?.po?.price.replace(',', '.')) > 0)
                : [];
        console.log('DADOS VER: ',  authData, this.props?.data, rows)
        const columns: ColDef[] = [
            {
                field: "quantity",
                headerName: "Qtd.",
                width: 100,
                row: true,
                renderCell: (params: valueFormatter, row: RowIdGetter) => {
                    return params.row?.quantity ?? 0;
                },
            },
            {
                field: "product_name",
                headerName: "Produto",
                flex: 0.7,
                row: true,
                renderCell: (params: valueFormatter, row: RowIdGetter) => {
                    return params.row.product_name ?? "";
                },
            },
            {
                field: "price",
                headerName: "Valor",
                flex: 1,
                row: true,
                renderCell: (params: valueFormatter, row: RowIdGetter) => {
                    if (!params?.row?.po?.price && !authData?.client?.id) {
                        return (
                            <MaskedDecimalInput
                                onChange={(e) => {
                                    let products =this.state.products;
                                    let product = {};
                                    let ind = products.findIndex(
                                        (prod) =>
                                            prod.product_id ==
                                            params.row.product_id
                                    );

                                    if (ind > -1) {
                                        products[ind]['price'] = e.target.value;
                                    } else {
                                        //product = params.row;
                                        product['product_id'] = params.row.product_id;
                                        product['quantity'] = params.row.quantity;
                                        product['quantity'] = params.row.quantity;
                                        product['price'] = e.target.value;
                                        products.push(product);
                                    }
                                    this.setState({ products });
                                    this.props.setData(products)
                                }}
                                id={"MaskedInput-" + Date.now().toString()}
                            />
                        );
                    }

                    let tmp =
                        (params?.row?.po?.price.toString() ?? "").replace(
                            /[^\d]/g,
                            ""
                        ) + "";
                    tmp = tmp.replace(/([0-9]{2})$/g, ",$1");
                    if (tmp.length > 6)
                        tmp = tmp.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");
                    if (tmp.length >= 10)
                        tmp = tmp.replace(
                            /([0-9]{3})\.([0-9]{3}),([0-9]{2}$)/g,
                            "$1.$2,$3"
                        );
                    return "R$ " + tmp;
                },
            },

            {
                field: "anotation",
                headerName: "Observação",
                flex: 1,
                row: true,
                renderCell: (params: valueFormatter, row: RowIdGetter) => {
                    if (!params.row?.po?.anotation && !authData?.client?.id) {
                        return (
                            <TextField
                                id={"textInput-" + Date.now().toString()}
                                placeholder={"Digite uma anotação ..."}
                                onChange={(e) => {
                                    let products =this.state.products;
                                    let product = {};
                                    let ind = products.findIndex(
                                        (prod) =>
                                            prod.product_id ==
                                            params.row.product_id
                                    );
                                    console.log('ANN: ', ind, products[ind])
                                    if (ind > -1) {
                                        console.log('AN1: ', ind, products)
                                        products[ind]['anotation'] = e.target.value;
                                    } else {
                                        product = params.row;
                                        product['anotation'] = e.target.value;
                                        products.push(product);
                                    }
                                    this.setState({ products });
                                    this.props.setData(products)
                                }}
                            />
                        );
                    } else {
                        return params.row?.po?.anotation;
                    }
                },
            },
        ];

        return (
            <Card style={{ marginBottom: 15 }} key={this?.props?.key ?? `datagrid-custom-${moment().milliseconds()}`}>
                <CardContent>
                    <Typography
                        style={{
                            maginBottom: 5,
                            alignItems: "center",
                        }}
                    >
                        <div style={{ flex: 1, alignItems: "center" }}>
                            <Button
                                variant={"text"}
                                onClick={() => {
                                    let filter = this.state.filter;
                                    const filterKey =
                                        "datagrid-custom-" +
                                        Date.now().toString();
                                    filter[this.props?.id ?? filterKey] =
                                        this.state?.filter[
                                            this.props?.id ?? filterKey
                                        ] == "block"
                                            ? "none"
                                            : "block";
                                    this.setState({
                                        ...this.state,
                                        filter,
                                    });
                                }}
                            >
                                <IndeterminateCheckBox />{" "}
                                {this.props?.title ?? this.state.title}
                            </Button>
                            {console.log('PPPPPP: ', this.props.provider?.provider_id, this.props.profile?.client?.id) }
                            {(this.props.provider?.provider_id == undefined && this.props.profile?.client?.id > 0) && (
                                <>
                                    <IconButton
                                        onClick={async () => {
                                            await this.props?.onAccept();
                                        }}
                                        style={{
                                            backgroundColor: "#025ea2",
                                            borderRadius: 5,
                                            marginLeft: 5,
                                        }}
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                    >
                                        <CheckCircleOutline
                                            style={{ color: "white" }}
                                            fontSize="small"
                                        />
                                    </IconButton>

                                    <IconButton
                                        onClick={async () => {
                                            await this.props?.onDecline();
                                        }}
                                        style={{
                                            backgroundColor: "#025ea2",
                                            borderRadius: 5,
                                            marginLeft: 5,
                                        }}
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                    >
                                        <BlockOutlined
                                            style={{ color: "white" }}
                                            fontSize="small"
                                        />
                                    </IconButton>
                                </>
                            )}
                        </div>
                    </Typography>
                    {/* DATAGRID */}
                    <div
                        style={{
                            alignItems: "center",
                            justifyContent: "start",
                            overflow: "auto",
                            marginTop: 15,
                            height: 350,
                            minHeight: 350,
                        }}
                    >
                        {window.innerWidth > 720 ? (
                            <DataGrid
                                sx={{
                                    "& .MuiDataGrid-root": {
                                        "& .MuiDataGrid-viewport": {
                                            maxWidth: "600px",
                                        },
                                    },
                                }}
                                rows={rows}
                                columns={columns}
                                spacing={0}
                                stickyHeader
                                disableClickEventBubbling
                                disableColumnMenu={true}
                                localeText={DEFAULT_LOCALE_TEXT}
                                pageSize={10}
                                rowsPerPageOptions={[10]}
                                pagination
                            />
                        ) : (
                            rows.map((row, key) => {
                                return (
                                    <Card
                                        key={`card-container${key}`}
                                        style={{
                                            marginTop: 15,
                                        }}
                                    >
                                        <CardContent>
                                            <List
                                                key={`list_field_${key}`}
                                                component="nav"
                                            >
                                                {Object.entries(row).map(
                                                    (field) => {
                                                        let headerName =
                                                            columns.find(
                                                                (column) =>
                                                                    column.field ===
                                                                    field[0]
                                                            );
                                                        if (
                                                            headerName &&
                                                            headerName.field !==
                                                                "id"
                                                        ) {
                                                            console.log(
                                                                field[1]
                                                            );
                                                            let value =
                                                                headerName.valueFormatter ??
                                                                headerName.renderCell;
                                                            value =
                                                                value ==
                                                                undefined
                                                                    ? field[1]
                                                                    : value(
                                                                          headerName.row ==
                                                                              true
                                                                              ? {
                                                                                    row,
                                                                                }
                                                                              : {
                                                                                    value: field[1],
                                                                                }
                                                                      );
                                                            if (
                                                                headerName.renderCell !==
                                                                undefined
                                                            ) {
                                                                console.log(
                                                                    row
                                                                );
                                                                value =
                                                                    headerName.renderCell(
                                                                        {
                                                                            value: field[1],
                                                                            row: row,
                                                                        },
                                                                        row
                                                                    );
                                                                console.log(
                                                                    value
                                                                );
                                                                return (
                                                                    <ListItem
                                                                        style={{
                                                                            paddingTop: 0,
                                                                            paddingBottom: 0,
                                                                        }}
                                                                    >
                                                                        <ListItemText
                                                                            primary={`${headerName.headerName}`}
                                                                            secondary={
                                                                                value
                                                                            }
                                                                        />
                                                                    </ListItem>
                                                                );
                                                            } else {
                                                                return (
                                                                    <ListItem
                                                                        style={{
                                                                            paddingTop: 0,
                                                                            paddingBottom: 0,
                                                                        }}
                                                                    >
                                                                        <ListItemText
                                                                            primary={`${headerName.headerName}`}
                                                                            secondary={`${value}`}
                                                                        />
                                                                    </ListItem>
                                                                );
                                                            }
                                                        }
                                                    }
                                                )}
                                            </List>
                                        </CardContent>
                                        <CardActions
                                            style={{
                                                justifyContent: "center",
                                            }}
                                        >
                                            {Object.entries(row).map(
                                                (field) => {
                                                    let headerName =
                                                        columns.find(
                                                            (column) =>
                                                                column.field ===
                                                                field[0]
                                                        );
                                                    if (
                                                        headerName &&
                                                        headerName.field == "id"
                                                    ) {
                                                        return headerName.renderCell(
                                                            {
                                                                value: field[1],
                                                                row,
                                                            },
                                                            row
                                                        );
                                                    }
                                                }
                                            )}
                                            <div></div>
                                        </CardActions>
                                    </Card>
                                );
                            })
                        )}
                        {this.props?.provider?.id > 0 && (
                            <div
                                style={{
                                    display: "flex",
                                    flex: 1,
                                    flexDirection: "row",
                                    justifyContent: "space-evenly",
                                    alignItems: "center",
                                }}
                            >
                                <Card style={{ flex: 1, flexBasis: "48%" }}>
                                    <CardContent>Pagamento Online</CardContent>
                                </Card>

                                <Card style={{ flex: 1, flexBasis: "48%" }}>
                                    <CardContent>
                                        Pagamento Na Entrega
                                    </CardContent>
                                </Card>
                            </div>
                        )}
                    </div>
                </CardContent>
            </Card>
        );
    }
}

const mapStateToProps = (store) => ({
    session: store.authReducer.data,
    snackbar: store.appReducer.snackbar,
});
const mapDispatchToProps = (dispatch) =>
    bindActionCreators({ setSnackbar }, dispatch);

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(DataGridCustom)
);

// File Input
export const FileInput = (props) => {
    const [imgSrc, setimgSrc] = useState(undefined);
    const [fileType, setfileType] = useState(undefined);
    const [value, setValue] = useState(props.value ?? undefined);
    const [file, setFile] = useState(props.file ?? undefined);
    const [replace, setReplace] = useState(!props.file ?? true);
    const [error, setError] = useState(false);
    const types = ["jpeg", "jpg", "png", "bmp", "pdf"];
    function handleChange(e) {
        const { value, id } = e.target;
        if (props.validate !== undefined) {
            if (props.validate(value)) {
                setError(false);
            } else {
                setError(true);
            }
        }
        // Assuming only image
        var file = e.target.files[0];
        var reader = new FileReader();
        var url = reader.readAsDataURL(file);

        reader.onloadend = function (e) {
            setimgSrc([reader.result]);
        }.bind(this);
        setfileType(file.name.split(".")[file.name.split(".").length - 1]);
        // TODO: concat files
        props.onChange({
            target: { id, value: e.target.files[0], type: "file" },
        });
        setValue(e.target.value);
        setFile(e.target.file);
    }
    return (
        <FormControl style={props?.style}>
            {file === undefined || replace ? (
                <div>
                    {value === undefined ? (
                        <Button
                            style={props?.style}
                            variant="outlined"
                            component="label"
                            endIcon={
                                <Icon
                                    name="arrow-circle-up"
                                    size={18}
                                    color="#025ea2"
                                />
                            }
                        >
                            {props?.label ?? ""}
                            <input
                                type="file"
                                hidden
                                onChange={handleChange}
                                onBlur={handleChange}
                                name={props?.id}
                                id={props?.id}
                            />
                        </Button>
                    ) : (
                        <div style={{ display: "flex", marginRight: 10 }}>
                            {types.includes(fileType.toLowerCase()) ? (
                                <img
                                    style={{
                                        ...props?.style,
                                        width: "64px",
                                        height: "60px",
                                    }}
                                    src={imgSrc}
                                />
                            ) : (
                                <Button
                                    style={props?.style}
                                    variant="outlined"
                                    component="label"
                                >
                                    {value
                                        .split(/(\\|\/)/g)
                                        .pop()
                                        .substring(0, 7) + "..."}
                                </Button>
                            )}
                            <Button
                                style={{ marginTop: 25, height: 60 }}
                                variant="outlined"
                                size="small"
                            >
                                <Icon
                                    name="remove"
                                    size={16}
                                    color="red"
                                    onClick={() => {
                                        setValue(undefined);
                                    }}
                                />
                            </Button>
                        </div>
                    )}
                </div>
            ) : (
                <div style={{ display: "flex" }}>
                    <Button
                        style={props.style}
                        variant="outlined"
                        component="label"
                        onClick={() => {
                            getApiDownloadFile(file);
                        }}
                        endIcon={
                            <Icon
                                name="arrow-circle-down"
                                size={22}
                                color="#025ea2"
                            />
                        }
                    >
                        Baixar Arquivo
                    </Button>
                    <Button
                        style={{ marginTop: 25, height: 60 }}
                        variant="outlined"
                        size="small"
                    >
                        <Icon
                            name="remove"
                            size={16}
                            color="red"
                            onClick={() => {
                                setReplace(true);
                            }}
                        />
                    </Button>
                </div>
            )}
        </FormControl>
    );
};
