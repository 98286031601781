import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { postResetPassword, postAuth, JWT_Decode } from "../../providers/api";
import { setDialog as authDialog, setAuth } from "../../actions/authAction";
import { setLoading } from "../../actions/appActions";

import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Slide from "@material-ui/core/Slide";

import { withRouter } from "react-router-dom";

import InputMask from "react-input-mask";
import Storage from "../../handlers/storage";
import { PostHandler } from "../../handlers";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import { BRANDING } from "../../../branding";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 350,
    display: "flex",
    flexDirection: "column",
    margin: "auto",
  },
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  media: {
    height: 140,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Login(props) {
  const [loginError, setLoginerror] = React.useState({
    success: false,
    message: "",
  });
  const [resetError, setReseterror] = React.useState({
    success: false,
    message: "",
  });
  const [termsAccepted, setTermsAccepted] = React.useState(true);
  const [signup, setSignup] = React.useState(false);
  const [loading, setloading] = React.useState(false);
  const [resetpwd, setResetpwd] = React.useState(false);
  const [dados, setDados] = React.useState({
    nome: undefined,
    cpf: undefined,
    email: "anderson@simdoctor.com.br",
    senha: "987654Aa#",
  });

  const classes = useStyles();

  useEffect(() => {
    let token = JSON.parse(localStorage.getItem("auth_token") ?? {});
    async function authInternal() {
      console.log("TOKEN: ", token);
      if (!token?.token) {
        await AuthInternalHandler({
          email: "ibacapp",
          password: "ibac$%2023app",
        });
      }
    }
    authInternal();

    // return false;
  }, []);

  function onChange(e) {
    let dt = dados;
    if (e.target.id == "cpf") {
      if (e.target.value.replace(/\D/g, "").length > 11) {
        return;
      }
      dt[e.target.id] = e.target.value.replace(/\D/g, "");
    } else {
      dt[e.target.id] = e.target.value;
    }

    setDados(dt);
  }

  async function onSubmitReset(e) {
    e.preventDefault();
    setloading(true);
    let data = await postResetPassword(dados);
    if (data !== undefined) {
      if (data.data.success) {
        setLoginerror({ success: false, message: "" });
        setloading(false);
        //window.location.href="/";
      } else {
        setLoginerror({ success: true, message: "" });
        setloading(false);
      }
      setReseterror({ success: true, message: data.data.message });
    }
  }
  // Cadastro
  const registerHandler = async () => {
    if (termsAccepted == false) {
      alert(
        "Termos de uso",
        "É necessário aceitar os termos de uso para se registrar."
      );
      return false;
    } else {
      const register = await PostHandler(
        "registrarPacienteLogin",
        {
          nome: dados?.nome.split(" ")[0],
          sobrenome: dados?.nome.replace(dados?.nome.split(" ")[0] + " ", ""),
          email: dados?.email,
          senha: dados?.senha,
          cpf: dados?.cpf,
          perfilId: BRANDING?.perfilId,
          //codigoFacebook: props.route?.params?.data?.id ?? "",
          //urlFotoFacebook: props.route?.params?.data?.picture?.data?.url,
        },
        props.navigation
      );
      if (register?.data?.success ?? register?.success) {
        alert("Sucesso! " + (register?.data?.message ?? ""));

        //props.navigation.navigate("Auth");
        window.location.href = "/login";
        return;
      }

      alert(
        "Problema ao cadastrar.\n" +
          (register?.message ?? register?.data?.message ?? "")
      );
    }
  };
  // Login
  async function onSubmit(e) {
    e.preventDefault();
    if (signup == true) {
      await registerHandler();
      return true;
    }
    let scheduleData = props?.history?.location?.state?.data;
    setloading(true);

    let token = JSON.parse(localStorage.getItem("auth_token") ?? {});
    let data = await postAuth({ ...dados }, token?.token);

    if (data?.error == undefined) {
      if (data.data.length > 0 && data.data[0]?.id > 0) {
        localStorage.setItem("user_info", JSON.stringify(data.data[0]));
        //localStorage.setItem("token", data.data[0].token);
        props.setAuth(data.data[0]);
        setLoginerror({ success: false, message: "" });
        setloading(false);
        let schedule = scheduleData;

        if (scheduleData?.data || scheduleData?.favorito != undefined) {
          let schedule = scheduleData;

          schedule.pacienteId = data.data[0]?.id;
          schedule.perfilId = data.data[0]?.perfilId;

          console.log("Fav: ", props?.history?.location?.state, schedule);

          if (scheduleData?.favorito == true) {
            let req = await PostHandler(
              `pacientes/${schedule?.pacienteId}/favoritos?perfilId=${schedule?.perfilId}`,
              {
                idDoutor: schedule?.doutorId,
              }
            );

            if (req?.data?.success) {
              window.location.href = "/favorites";
            }

            return;
          }
          const scheduling = await PostHandler(
            `consultas`,
            schedule,
            props.navigation
          );
          // console.log("AGENDAMENTOS: ", scheduling);
          if (scheduling?.success) {
            alert(
              "Horário reservado\no seu horário foi reservado, e será confirmado após efetuar o pagamento !"
            );
            window.location.href = "/history";
          }
        } else {
          window.location.href = "/";
        }
      } else {
        setLoginerror({ success: true, message: data.data.message });
        setloading(false);
      }
    }
  }
  //
  return (
    <div>
      <div style={{ marginBottom: 25, marginTop: 35 }}>
        <img
          src={BRANDING?.loginLogo?.img}
          style={{ display: "flex", margin: "auto", marginBottom: 15 }}
          height={BRANDING?.loginLogo?.height}
        />
      </div>
      {!resetpwd ? (
        <form id="loginform" onSubmit={onSubmit}>
          <Card className={classes.root}>
            <CardContent>
              {signup == true && (
                <div>
                  <div>
                    <TextField
                      error={loginError.success}
                      helperText={loginError.success ? loginError.message : ""}
                      type="text"
                      fullWidth
                      id="nome"
                      label="Nome"
                      variant="outlined"
                      onChange={onChange}
                      onBlur={onChange}
                    />
                  </div>
                  <br />
                  <div>
                    <TextField
                      error={loginError.success}
                      helperText={loginError.success ? loginError.message : ""}
                      type="text"
                      fullWidth
                      id="cpf"
                      label="CPF"
                      variant="outlined"
                      onChange={onChange}
                      onBlur={onChange}
                    />
                  </div>
                  <br />
                </div>
              )}
              {/** LOGIN */}
              <div>
                <TextField
                  error={loginError.success}
                  helperText={loginError.success ? loginError.message : ""}
                  type="text"
                  fullWidth
                  id="email"
                  label="E-Mail"
                  variant="outlined"
                  onChange={onChange}
                  onBlur={onChange}
                />
              </div>
              <br />
              <div>
                <TextField
                  error={loginError === true ? true : false}
                  //helperText={ loginError ? "E-mail ou senha inválidos." : ""}
                  type="text"
                  fullWidth
                  id="senha"
                  type="password"
                  label="Senha"
                  variant="outlined"
                  onChange={onChange}
                  onBlur={onChange}
                />
              </div>
            </CardContent>

            <CardActions style={{ flexDirection: "column" }}>
              <div
                style={{
                  marginBottom: 10,
                  marginLeft: 10,
                  alignSelf: "start",
                  cursor: "pointer",
                  color: "#025ea2",
                }}
              >
                {signup != true ? (
                  <FormControlLabel
                    control={<Checkbox color="primary" defaultChecked />}
                    label="Ficar conectado nesse computador"
                  />
                ): undefined}
                <a
                  onClick={() => {
                    setResetpwd(true);
                  }}
                >
                  Esqueceu a senha?
                </a>
                <div style={{ marginTop: 10 }}>
                  {signup == false ? (
                    <div>
                      Não possui uma conta ?{" "}
                      <a onClick={() => setSignup(true)}>Cadastre-se</a>
                    </div>
                  ) : (
                    <div>
                      Já possui uma conta ?{" "}
                      <a onClick={() => setSignup(false)}>Entre</a>
                    </div>
                  )}
                </div>
              </div>
              <br />
              {!loading ? (
                <Button
                  variant="contained"
                  size="large"
                  fullWidth
                  color="primary"
                  disableElevation
                  type="submit"
                  form="loginform"
                  style={{
                    marginLeft: 0,
                    //background: 'linear-gradient(45deg, #025ea2 30%, #0086e8 90%)',
                  }}
                >
                  {signup == false ? "Entrar" : "Cadastrar"}
                </Button>
              ) : (
                <CircularProgress style={{ margin: "auto" }} />
              )}
            </CardActions>
          </Card>
        </form>
      ) : (
        <form id="loginform" onSubmit={onSubmitReset}>
          <Card className={classes.root}>
            <CardContent>
              <div>
                {/*<InputMask id="username" label="Usuário" mask="999.999.999-99" maskChar=" " onChange={onChange}>
                {(inputProps) =>
                  <TextField
                  error={loginError === true ? true : false}
                  helperText={ loginError ? "Usuário ou senha invalidos." : ""}
                  fullWidth id="username" label="Usuário" variant="outlined" onChange={onChange} />
                }
              </InputMask> */}
                <TextField
                  error={resetError.success}
                  helperText={resetError.success ? resetError.message : ""}
                  type="text"
                  fullWidth
                  id="email"
                  type="text"
                  label="E-mail"
                  variant="outlined"
                  onChange={onChange}
                  onBlur={onChange}
                />
              </div>
            </CardContent>

            <CardActions style={{ flexDirection: "column" }}>
              <div
                style={{
                  marginBottom: 10,
                  marginLeft: 10,
                  alignSelf: "start",
                  cursor: "pointer",
                  color: "#025ea2",
                }}
              >
                <a
                  onClick={() => {
                    setResetpwd(false);
                  }}
                >
                  Login
                </a>
              </div>
              <br />
              {!loading ? (
                <Button
                  variant="contained"
                  size="large"
                  fullWidth
                  color="primary"
                  disableElevation
                  type="submit"
                  form="loginform"
                  style={{
                    marginLeft: 0,
                    background:
                      "linear-gradient(45deg, #025ea2 30%, #0086e8 90%)",
                  }}
                >
                  SOLICITAR
                </Button>
              ) : (
                <CircularProgress style={{ margin: "auto" }} />
              )}
            </CardActions>
          </Card>
        </form>
      )}
    </div>
  );
}
const mapStateToProps = (store) => ({
  aDialog: store.authReducer.authDialog,
  data: store.authReducer.data,
  loading: store.appReducer.loading,
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ setLoading, authDialog, setAuth, JWT_Decode }, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));
