import React from "react";
import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  InputLabel,
  ListItem,
  TextField,
} from "@material-ui/core";
import { ScrollView, View } from "react-native";
import { PostHandler, UploadHandler } from "../../handlers";
import moment from "moment";
import Colors from "../../commons/Colors";

export function MainDialog(props: any) {
  const { setProfileDialog, profileDialog } = props;
  const handleClose = () => {
    setProfileDialog({ open: false });
    if (props.profileDialog?.onCancel) {
      props.profileDialog.onCancel();
    }
  };
  //console.log('MAIN DIALOG: ',props)

  return (
    <Dialog onClose={handleClose} open={profileDialog?.open}>
      {profileDialog?.type == "profile" && (
        <ProfileDialog {...props} handleClose={handleClose} />
      )}
      {profileDialog?.type == "help" && (
        <HelpDialog {...props} handleClose={handleClose} />
      )}
      {profileDialog?.type == "yesno" && (
        <YesNoDialog
          {...props}
          data={profileDialog}
          handleClose={handleClose}
        />
      )}
    </Dialog>
  );
}

export function YesNoDialog(props) {
  const styles = {
    listItem: {
      borderBottomColor: "grey",
      borderBottomWidth: 1,
      marginHorizontal: 15,
    },
  };
  console.log("ALERT PROPS:", props);
  return (
    <div>
      <DialogTitle>{props?.data?.title ?? ""}</DialogTitle>
      <DialogContent>
        <InputLabel style={{ lineHeigth: "1.5" }}>
          {props?.data?.body ?? props?.data?.title ?? ""}
        </InputLabel>
        <View style={{ marginVertical: 20 }}></View>
        <Divider
          style={{
            //backgroundColor: Colors.primary,
            marginHorizontal: 20,
            marginVertical: 20,
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant={"contained"}
          color={"primary"}
          onClick={() => {
            console.log('DATA LINK: ',props.data?.onSave != undefined);
            props?.data?.singleButton == undefined || props.data.onSave != undefined
              ? props.data.onSave()
              : props.handleClose();
          }}
        >
          {props?.data?.singleButton == undefined ? "Sim" : "OK"}
        </Button>
        {props?.data?.singleButton == undefined && (
          <Button
            variant={"contained"}
            color={"default"}
            onClick={props.handleClose}
          >
            Não
          </Button>
        )}
      </DialogActions>
    </div>
  );
}

export function ProfileDialog(props) {
  const [nome, setNome] = React.useState(props.auth?.nome ?? "");
  const [sobrenome, setSobrenome] = React.useState(props.auth?.sobrenome ?? "");
  const [email, setEmail] = React.useState(props.auth?.email ?? "");
  const [cpf, setCPF] = React.useState(props.auth?.cpf ?? "");
  const [telefone, setTelefone] = React.useState(props.auth?.celular ?? "");
  const [urlFoto, setUrlFoto] = React.useState(props.auth?.urlFoto ?? "");

  const onSave = async () => {
    
    if(props?.onSave != undefined){
      setProfileDialog({ open: false });
      props.onSave();
    }
    
    let user = JSON.parse(localStorage.getItem("user_info"));
    user.nome = nome;
    user.sobrenome = sobrenome;
    user.email = email;
    user.celular = telefone;
    user.cpf = cpf;
    localStorage.setItem("user_info", JSON.stringify(user));
    props.setAuth(user);
    let data = {
      nome: nome,
      sobrenome: sobrenome,
      cpf: cpf,
      email: email,
      celular: telefone,
      perfilId: props?.auth?.perfilId ?? props?.auth?.perfilUsuarioId,
    };

    let change = await PostHandler(
      `pacientes/${props.auth?.id}/atualizar`,
      data,
      props.navigation
    );

    console.log(change);
    props.handleClose();
  };

  return (
    <div>
      <DialogTitle>Meu Perfil</DialogTitle>
      <DialogContent>
        {(cpf ?? "").length <= 10 ?
        <div style={{marginBottom: 15, paddgingLeft: 10, paddingTop: 5, paddingBottom: 5, backgroundColor: '#fff0f0', borderWidth: 1, borderColor: '#333'}}>
          O Campo CPF é Obrigatório.
        </div> : undefined}
        <View
          style={{
            paddingBottom: 25,
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <View >
            <label for="file">
              <Avatar
                style={{borderWidth:1,borderColor: Colors.primary}}
                src={(urlFoto ?? "").length > 0 ? urlFoto : undefined}
                alt={props.auth?.nome}
              >
                {" "}
                {(props.auth?.nome ?? "Pa")?.charAt(0)}{" "}
              </Avatar>
            </label>
          </View>
          <View style={{marginStart: 25}}>
          <small>
              * clique na foto ao lado para alterar.
            </small>
            <input
              id={"file"}
              style={{ display: "none" }}
              type={"file"}
              accept="image/*"
              onInput={async (e) => {
                console.log(e.target.files);
                let file = e.target.files[0];
                file.uri = e.target.value;
                let upload = await UploadHandler(props.auth?.id, {
                  file,
                  perfilId: props.auth?.perfilId,
                  web: true,
                });
                if (upload?.success) {
                  setUrlFoto(
                    upload?.data?.urlFoto + "?" + moment().format("x")
                  );

                  let user = JSON.parse(localStorage.getItem("user_info"));
                  user.nome = user.nome;
                  user.sobrenome = user.sobrenome;
                  user.email = user.email;
                  user.celular = user.celular;
                  user.cpf = user.cpf;
                  user.urlFoto =
                    upload?.data?.urlFoto + "?" + moment().format("x");

                  localStorage.setItem("user_info", JSON.stringify(user));
                  props.setAuth(user);
                }
                console.log("UPLOAD: ", upload, e.target.files[0]);
              }}
            />
          </View>
        </View>
        <div>
          <div
            style={{
              display: "flex",
              flex: 1,
              flexWrap: "wrap",
              flexDirection: "row",
              justifyContent: "space-around",
              width: 450,
            }}
          >
            <div>
              <TextField
                size="small"
                style={{ width: 220 }}
                label="Nome"
                variant="outlined"
                value={nome}
                onChange={(e) => {
                  setNome(e.target.value);
                }}
              />
            </div>
            <div>
              <TextField
                size="small"
                style={{ width: 220 }}
                label="Sobrenome"
                variant="outlined"
                value={sobrenome}
                onChange={(e) => {
                  setSobrenome(e.target.value);
                }}
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flex: 1,
              flexWrap: "wrap",
              flexDirection: "row",
              justifyContent: "space-around",
              width: 450,
              marginTop: 10,
            }}
          >
            <div>
              <TextField
                size="small"
                style={{ width: 250 }}
                label="E-mail"
                variant="outlined"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
            </div>
            <div>
              <TextField
                size="small"
                style={{ width: 190 }}
                label="Telefone"
                variant="outlined"
                value={telefone}
                onChange={(e) => {
                  setTelefone(e.target.value);
                }}
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flex: 1,
              flexWrap: "wrap",
              flexDirection: "row",
              justifyContent: "space-between",
              width: 450,
              marginTop: 10,
            }}
          >
            <div>
              <TextField
                size="small"
                style={{ width: 250 }}
                label="CPF"
                variant="outlined"
                value={cpf}
                onChange={(e) => {
                  setCPF(e.target.value);
                }}
              />
            </div>
            <div></div>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button variant={"contained"} color={"primary"} onClick={onSave}>
          Gravar
        </Button>
      </DialogActions>
    </div>
  );
}

export function HelpDialog(props) {
  const styles = {
    listItem: {
      borderBottomColor: "grey",
      borderBottomWidth: 1,
      marginHorizontal: 15,
    },
  };
  return (
    <div>
      <DialogTitle>Ajuda</DialogTitle>
      <DialogContent style={{ height: 301 }}>
        <ScrollView style={{ innerHeight: 150 }}>
          <div
            style={{
              marginStart: 15,
              marginTop: 10,
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <InputLabel
              style={{
                fontSize: 20,
              }}
            >
              {" "}
              Como Usar ?
            </InputLabel>
            <InputLabel style={{ lineHeight: "2.0" }}>
              1 - Faça a busca pelo seu profissional, filtrando por
              Especialidade, Formação, Valor, Gênero, Idioma e outros filtros.
              <br />
              2 - Selecione o profissional que melhor se identifica.
              <br />
              3 - Escolha uma data e horário para agendar a sua consulta.
              <br />4 - Efetue o pagamento e pronto.
            </InputLabel>

            <div style={{ marginTop: 15 }}>
              <InputLabel style={{ lineHeight: "2.0", textAlign: "center" }}>
                Clique para{" "}
                <a href="https://simdoctor.com.br">Mais Informações</a>
              </InputLabel>
            </div>
          </div>
          {/*<Divider
            bold={true}
            style={{
              //backgroundColor: Colors.primary,
              marginHorizontal: 20,
              marginVertical: 10,
            }}
          />
          <div>
            <ListItem
              titleNumberOfLines={2}
              titleStyle={{fontSize: 18}}
              style={styles.listItem}
              title="Realize seu cadastro em nosso aplicativo"
              onPress={() => {
                this.setState({ ...this.state, changePassword: true });
              }}
              left={(props) => (
                <Avatar
                  style={{
                    //backgroundColor: Colors.secondary,
                  }}
                  size={24}
                  label="1º"
                />
              )}
            />
            <ListItem
              titleNumberOfLines={2}
              titleStyle={{fontSize: 18}}
              style={styles.listItem}
              title="Selecione o Profissional que você deseja"
              onPress={() => {
                this.setState({ ...this.state, changePassword: true });
              }}
              left={(props) => (
                <Avatar
                  style={{
                    //backgroundColor: Colors.secondary,
                  }}
                  size={24}
                  label="2º"
                />
              )}
            />

            <ListItem
              titleNumberOfLines={2}
              titleStyle={{fontSize: 18}}
              style={styles.listItem}
              title="Escolha o dia e a hora para agendamento"
              onPress={() => {
                this.setState({ ...this.state, changePassword: true });
              }}
              left={(props) => (
                <Avatar
                  style={{
                    //backgroundColor: Colors.secondary,
                  }}
                  size={24}
                  label="3º"
                />
              )}
            />

          <ListItem
              titleNumberOfLines={2}
              titleStyle={{fontSize: 18}}
              style={styles.listItem}
              title="Realize o pagamento online"
              onPress={() => {
                this.setState({ ...this.state, changePassword: true });
              }}
              left={(props) => (
                <Avatar
                  style={{
                    //backgroundColor: Colors.secondary,
                  }}
                  size={24}
                  label="4º"
                />
              )}
                />
          </div> */}
        </ScrollView>
      </DialogContent>
    </div>
  );
}
