import axios from "axios";
import { Platform } from "react-native";
import Storage from "../handlers/storage";
const apiHost = "https://api.clinicas2.simdoctor.com.br/v1/clinicas"

export const postAuth = async (params = {},  token: string = '') => {
  let param = {};

  if(params.authTokenBio){
    param = { authTokenBio : params.authTokenBio}
  }else{
    param =  {
      email: params?.email,
      senha: params?.password,
    }
  }
const data = new FormData();
 Object.entries(param)
    .map(([key, val]) => data.append(key, val));
    
  const options = {
    method: 'POST',
    data,
    url: apiHost + '/pacientes/login',
    headers: {
      'Authorization': 'Bearer ' + (token ?? '' ),
      'Content-Type': 'multipart/form-data',
    },
    validateStatus: function (status: any) {
      return status < 500;
    }
  };
  //console.log('OPTIONS: ', options);
  try {
    const response = await axios(options);
    return response;
  } catch (error) {
    console.error(error)
    return { data: { data: [], success: false, message: error.message || error } }
  }
};

export const postInternalAuth = async (params = {}) => {
  
  //const data = new FormData();
  params.perfil_id = null
  /*Object.entries(params).filter(([k,v]) => k !== 'perfil_id').map(([k,v]) => data.append(k,v+''));
  console.log('INTERNAL LOGIN PARAMS',apiHost + '/loginInterno' ,data);*/
  const data = Object.entries(params)
    .map(([key, val]) => `${key}=${encodeURIComponent(val)}`)
    .join('&');
    
  const options = {
    method: 'POST',
    data,
    url: apiHost + '/loginInterno',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    validateStatus: function (status: any) {
      return status < 500;
    }
  };
  try {
    const response = await axios(options);
    return response;
  } catch (error) {
    console.error('FALHA:: ',error)
    return { data: { data: [], success: false, message: error.message || error } }
  }
};

export const postRecovery = async (params = {}) => {
  const data = Object.entries(params)
    .map(([key, val]) => `${key}=${encodeURIComponent(val)}`)
    .join('&');
  let token = await Storage.get('auth_token');
  const options = {
    headers: {
      Authorization: 'Bearer ' + token?.token ?? ''
    },
    method: 'POST',
    data: params,
    url: apiHost + '/esqueciSenhaPaciente',
    validateStatus: function (status: any) {
      return status < 500;
    }
  };
  try {
    const response = await axios(options);
    return response;
  } catch (error) {
    return { data: { data: [], success: false, message: error.message || error } }
  }
};

export const getApi = async (uri: string, params: any = undefined, token: string = '') => {
  const table = 'send_requests';
  try {
    let params1 = !params ? '' : Object.entries(params)
    .map(([key, val]) => {
      if(Array.isArray(val)){
        //console.log('A : ',key,val);
        return Object.entries(val)
          .map(([key1, val1]) => {
            //console.log('B : ',key1,val1);
            if(val1 != undefined && val1.length > 0){
              return `${key}[]=${encodeURIComponent(val1+'')}`
            }
            //return `${key}[]=${encodeURIComponent(val1+'')}`;
          }).join("&")
      }

      if(val != undefined && val != ""){
        return `${key}=${encodeURIComponent(val??'')}`
      }
      //return `${key}=${val}`;
    });
    params1 = params1.filter( item => item != undefined && item != "").join("&");
    
    console.log(`URL :: ${apiHost}/${uri}${params ? '?' + params1 : ''}`);
    const response = await axios.get(`${apiHost}/${uri}${params ? '?' + params1 : ''}`,
      {
        headers: {
          Authorization: 'Bearer ' + token
        },
        validateStatus: function (status: any) {
          return status < 500;
        }
      });
      //console.log(response.data);
    return response;
  } catch (error) {
    console.error(error)
    return { data: { data: [], success: false, message: error.message || error } }
  }
}

export const deleteApi = async (uri: string, params: any, token: string) => {
  try {
    const response = await axios.delete(`${apiHost}/${uri}${params ? "?" + params : ''}`,
      {
        headers: {
          Authorization: 'Bearer ' + token
        },
        validateStatus: function (status: any) {
          return status < 500;
        }
      });
    return response;
  } catch (error) {
    console.error(error)
    return { data: { data: [], success: false, message: error.message || error } }
  }
}

export const postApi = async (uri: string, params: any, token: string) => {
  const options = {
    method: 'POST',
    data: params,
    url: `${apiHost}/${uri}`,
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'multipart/form-data',
    },
    validateStatus: function (status: any) {
      console.log('RESPONSE STATUS: ', status);
      return status < 500;
    }
  };
  try {
    //console.log('POST REQUEST: ', options)
    const response = await axios(options);
    
    return response;
  } catch (error) {
    console.error(error)
    return { data: { data: [], success: false, message: error.message || error } }
  }
};

export const putApi = async (uri: string, params: any, token: string) => {
  const options = {
    method: 'POST',
    data: params,
    url: `${apiHost}/${uri}`,
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'multipart/form-data',
    },
    validateStatus: function (status: any) {
      return status < 500;
    }
  };
  try {
    const response = await axios(options);
    return response;
  } catch (error) {
    console.error(error)
    return { data: { data: [], success: false, message: error.message || error } }
  }
};

//Download Document
export const getApiDownloadFile = async (uri: string, params: any, token: string) => {
  const data = Object.entries(params)
    .map(([key, val]) => `${key}=${encodeURIComponent(val)}`)
    .join('&');

  axios({
    method: 'get',
    url: `${apiHost}/${uri}${params ? '?' + data : ''}`,
    responseType: 'arraybuffer',
    //data: dates
  }).then(function (response) {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', params); //or any other extension
    document.body.appendChild(link);
    link.click();
  }).catch((error) => {
    console.error(error)
    console.log('Whoops! Houve um erro.', error.message || error)
    return { data: { data: [], success: false, message: "problema ao se conectar com o servidor!" } }
  });
}

// Upload Image Handler
export const postApiUploadImage = async (uri: string, params: any, token: string) => {
  console.log('NOME : '+params.file?.name)
  let name = params.file.name.length > 0 ? params.file?.name :  params.file.uri.split('/').pop();
  let ext = params.file.uri.split('/').pop()
  let match = /\.(\w+)$/.exec(ext); 
  let type = match ? `image/${match[1]}` : `image`;

  let data = new FormData();
  
  if(params?.web == true){
    data.append("foto", params.file);
  }else{
    data.append('foto', {
      uri: Platform.OS === 'android' ? params.file.uri : params.file.uri.replace('file://', ''),
      name,
      type
    });
  }
  data.append('perfilId', params.perfilId)
    if(params.consultaId){
      data.append('consultaId', params.consultaId)
    }
  
  const options = {
    method: 'POST',
    data,
    url: `${apiHost}/${uri}`,
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: 'Bearer ' + token,
    },
    validateStatus: function (status: any) {
      return status < 500;
    }
  };
  try {
    const response = await axios(options);
    console.log(`${apiHost}/${uri}`, response.data, response.status)
    return response.data;

  } catch (error) {
    console.error(error)
    return { data: { data: [], success: false, message: error.message || error } }
  }
}

// Upload Document Handler
export const postApiUploadDocument = async (uri: string, params: any, token: string) => {
  console.log(uri);
  let name = params.file.uri.split('/').pop();
  let data = new FormData();
  data.append('arquivo', {
    uri: Platform.OS === 'android' ? params.file.uri : params.file.uri.replace('file://', ''),
    name: name,
    type: '*/*',
  });
  data.append('title', params.file.name)
  if(params.consultaId){
    data.append('consultaId', params.consultaId)
  }
  console.log(data)
  const options = {
    method: 'POST',
    data,
    url: `${apiHost}/${uri}`,
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: 'Bearer ' + token,
    },
    validateStatus: function (status: any) {
      return status < 500;
    }
  };

  try {
    const response = await axios(options);
    return response.data;

  } catch (error) {
    return { data: { data: [], success: false, message: error.message || error } }
  }
}