import React, { useState, useEffect }  from 'react';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';
/** Assets */

import {setSnackbar} from '../../../actions/appActions'
//import './index.css'

import { makeStyles } from '@material-ui/core/styles';
import Colors from '../../../commons/Colors';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    width: '100%',
    bottom: 0,
    flexGrow: 1,
    height: 65,
    //marginStart: '-24px !important',
    //marginEnd: '-24px !important',
    background: Colors.quinary
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

function Footer(props) {
  let data = [];
  const doSome = async () => {
    if(props.invoices.length === 0){
      const response = await getInvoices();
      //console.log(response.data)
      props.setInvoices(response.data);
    }
  }
  // useEffect(() =>{doSome();}, [data])
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div style={{marginTop: 10, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
        <div style={{color: 'white', marginEnd: 5}}> powered by: Simdoctor<span> &nbsp;</span></div>
        { /*<div>  <img height={25} src={require('../../../../assets/images/logo_sd.png')} /></div> */}
      </div>
    </div>
  );
}


const mapStateToProps = store => ({
  snackbar: store.appReducer.snackbar,
});
const mapDispatchToProps = dispatch =>
bindActionCreators({ setSnackbar}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Footer)