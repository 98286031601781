import React, { Component, Fragment } from "react";
import { View, ScrollView } from "react-native-web";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
//
import Colors from "../../commons/Colors";
//
import {
  setSnackbar,
  setProfileDialog,
  setTimer,
} from "../../actions/appActions";
import { toMoney } from "../../handlers/money";
import { DeleteHandler, GetHandler, PostHandler } from "../../handlers";
import {
  Avatar,
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  Icon,
  IconButton,
  InputLabel,
  Portal,
} from "@material-ui/core";
import {
  ArrowLeft,
  ArrowLeftSharp,
  BookmarkBorderOutlined,
  BookmarkOutlined,
  BookmarkSharp,
  CheckBox,
  Filter1Sharp,
  FilterBAndWSharp,
  Star,
} from "@material-ui/icons";
import CalendarHeader from "./Profile/calendarHeader";
import Calendar from "./Profile/calendar";
import ProfissionalItems from "./profissionalItems";
import moment from "moment";
import { Alert, Dimensions, TouchableOpacity } from "react-native";
import { Link } from "react-router-dom";

function CalendarioCompleto(props: any) {
  const [reloading, setReloading] = React.useState(false);
  const [date, setDate] = React.useState(moment());
  const [schedules, setSchedules] = React.useState([]);
  //console.log('CAL COMPLETO: ', props);
  const getSchedules = async (data, first = false) => {
    setReloading(true);
    const response = await GetHandler(
      `agenda/listaHorarios/${props.dados?.id ?? props.dados?.doutorId}`,
      { data, perfilId: props.dados?.perfilId },
      props?.navigation
    );
    const schedules = response?.data ?? [];
    //console.log("AGENDAS:", schedules);
    //if (!first) {
    //props.setData(schedules);
    // setSchedules(schedules);
    //}else{
    setSchedules(schedules);
    //}
    setReloading(false);

    //console.log(schedules);
    return schedules;
  };
  React.useEffect(() => {
    async function fetchData() {
      //console.log(date.format("yyyy-MM-DD"));
      await getSchedules(date.format("yyyy-MM-DD"));
    }
    fetchData();
    //return true;
  }, []);

  return (
    <View>
      <CalendarHeader
        dataStart={date}
        getData={(dataStart) => {}}
        setDate={async (dataStart) => {
          props.setDate(dataStart);
          setDate(dataStart);
          //const scheduleData = date
          //scheduleData.data = dataStart.format("yyyy-MM-DD");
          await getSchedules(dataStart.format("yyyy-MM-DD"));
        }}
        month={true}
      />
      <ScrollView style={{ maxHeight: 400 }}>
        <Calendar
          refreshing={reloading}
          date={date}
          data={schedules}
          doutor={props.dados}
          type={props?.type}
          setTime={(time) => {
            //const scheduleData = this.state.scheduleData;
            //scheduleData.horario = time;
            props.setTime(time);
            // console.log(scheduleData);
          }}
        />
      </ScrollView>
      {schedules[0]?.dataDisponivel && (
        <Button
          fullWidth
          color={"primary"}
          variant={"contained"}
          style={{ marginTop: 15 }}
          onClick={async () => {
            //alert("clicou");
            await props.sendSchedule();
          }}
        >
          Agendar
        </Button>
      )}
    </View>
  );
}
class SearchList extends Component {
  state = {
    dataStart: moment(),
    profile: undefined,
    doctorData: undefined,
    showOrderpanel: false,
    scheduleData: {
      pacienteId: undefined,
      perfilId: undefined,
      doutorId: this.props.dados?.id,
      horario: undefined,
      data: moment().format("yyyy-MM-DD"),
      tipoAtendimento: this.props?.location?.tipoAtendimento ?? "video",
      //linkPagSeguro: true,
      linkAsaas:true,
      formaPagAsaas: ""
    },
    orderBy: [
      { column: "pontuacao.desc", label: "Avaliação", checked: false },
      {
        column: "totalConsultasAtendidas.desc",
        label: "Número de consultas",
        checked: false,
      },
      { column: "precoConsulta.desc", label: "Maior Preço", checked: false },
      { column: "precoConsulta.asc", label: "Menor Preço", checked: false },
      { column: "pontuacao.desc", label: "Tempo de trabalho", checked: false },
    ],
    results: [],
    pageOptions: undefined,
    refreshing: false,
  };

  async setOrder(item) {
    let orderBy = this.state.orderBy;
    orderBy.find((order) => order.label == item.label).checked = !item.checked;
    this.setState({
      orderBy,
      showOrderpanel: false,
    });
    await this.props.getData(undefined, orderBy);
  }

  sendSchedule = async (item: any) => {
    let authData = JSON.parse(localStorage.getItem("user_info"));
    
    let scheduleData = this.state.scheduleData;
    scheduleData.doutorId = item?.id ?? item?.doutorId;
    scheduleData.perfilId = item?.perfilId;
    scheduleData.pacienteId = authData?.id;
    scheduleData.paciente = {
      nome: authData?.nome ? `${authData?.nome} ${authData?.sobrenome}` : "",
      cpf: authData?.cpf ?? "",
      email: authData?.email ?? ""
    }

    let favorito = {};
    if (item?.favorito == true) {
      favorito.pacientId = undefined;
      favorito.doutorId = item?.id;
      favorito.perfilId = item?.perfilId;
      favorito.favorito = true;
      scheduleData = favorito;
    }

    this.setState({ ...this.state, reloading: true });
    //console.log("PP: ", authData, scheduleData, item);
    if (scheduleData.horario === undefined && item?.favorito != true) {
      /*alert(
        "Selecione um horário",
        "é necessário escolher um horário disponível para agendar a consulta"
      );*/
      this.props.setProfileDialog({
        title: "Selecione um horário",
        body: "É necessário escolher um horário disponível para agendar a consulta",
        open: true,
        singleButton: true,
        type: "yesno",
        onSave: undefined,
        onCancel: () => {},
      });
      this.setState({ ...this.state, reloading: false });
      return false;
    }
    /*if (!this.state.terms) {
      Alert.alert(
        "Termos e condições",
        " é necessário concordar com os termos e condições de agendamento"
      );
      this.setState({ ...this.state, reloading: false });
      return false;
    }*/
    this.setState({ ...this.state, reloading: true });
    if (
      !scheduleData.pacienteId ||
      !scheduleData.perfilId ||
      !scheduleData.doutorId
    ) {
      this.props.setProfileDialog({
        title: "Login Obrigatório",
        body: "Faça login para continuar!",
        open: true,
        singleButton: true,
        type: "yesno",
        onSave: () => {
          this.props.setProfileDialog({open: false})
          this.props.history.push({
            pathname: "/login",
            state: {
              data: scheduleData,
            },
          });
          return true;
        },
        onCancel: () => {},
      });
    } else {
      if((authData?.cpf ?? "").length <= 10){
        this.props.setProfileDialog({open: true, type: 'profile'});
        return false;
      }
      const scheduling = await PostHandler(
        `consultas`,
        scheduleData,
        undefined
      );

      if (scheduling?.data?.success) {
        let title = "Atenção\n\nO seu horário foi reservado!";
        let body =
          "Seu Horário será confirmado após efetuar o pagamento!" +
          "\n Deseja ser redirecionado para pagamento da sessão agora ?";
        this.props.setProfileDialog({
          title,
          body,
          open: true,
          type: "yesno",
          onSave: () => {
            window.open(
              scheduling?.data?.data?.linkCobranca?.linkCobranca,
              "_blank"
            );
            window.location.href = "/history";
          },
          onCancel: () => (window.location.href = "/history"),
        });
      } else {
        //console.log('AGAGAG:', scheduling)
        this.props.setProfileDialog({
          title: "Problema ao agendar",
          body:
            scheduling?.data?.message ??
            "Houve um problema ao tentar efetuar o agendamento",
          open: true,
          singleButton: true,
          type: "yesno",
          onSave: () => {},
          onCancel: () => {},
        });
      }
    }
    this.setState({ ...this.state, reloading: false });
  };

  render() {
    return (
      <View>
        <View
          style={{
            //backgroundColor: Colors.primary,
            paddingHorizontal: 15,
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <View style={{marginEnd: 15}}>
            <IconButton
              onClick={() => {
                window.location.href = "/";
              }}
              color="primary"
              aria-label="upload picture"
              component="label"
            >
              <ArrowLeftSharp />
            </IconButton>
          </View>
          <View>
            <InputLabel style={{ color: Colors.primary }}>
              Resultados
            </InputLabel>
          </View>
          <View>
          <Button
            color={"default"}
            labelStyle={{ }}
            onClick={() => {
              this.setState({
                ...this.state,
                showOrderpanel: !this.state.showOrderpanel,
              });
            }}
          >
            <FilterBAndWSharp size={22} /> ORDENAR
          </Button>
          </View>
          
        </View>

        <View
          style={{
            zIndex: 990,
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            paddingHorizontal: 15,
            margin: 3,
          }}
        >
          
          {this.state.showOrderpanel && (
            <Portal>
              <View
                style={{
                  position: "absolute",
                  top: 100,
                  left: Dimensions.get("window").width / 2 - 400,
                  backgroundColor: "#e7e7e7",
                  borderWidth: 1,
                  borderColor: "#a7a7a7",
                  borderRadius: 3,
                  width: 300,
                  padding: 10,
                  zIndex: 999,
                }}
              >
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <View>
                    <InputLabel
                      style={{ InputLabelAlign: "center", paddingVertical: 10 }}
                    >
                      {" "}
                      Ordernar por:{" "}
                    </InputLabel>
                  </View>
                  <View>
                    <Button
                      variant={"text"}
                      onClick={() => {
                        this.setState({ showOrderpanel: false });
                      }}
                    >
                      X
                    </Button>
                  </View>
                </View>

                <ScrollView
                  nestedScrollEnabled={true}
                  style={{
                    zIndex: 9999,
                  }}
                >
                  {(this.state?.orderBy ?? []).map((item) => {
                    return (
                      <View
                        style={{
                          flex: 1,
                          zIndex: 998,
                        }}
                      >
                        <FormControlLabel
                          label={item.label}
                          control={
                            <Checkbox
                              color={"primary"}
                              checked={item.checked}
                              onClick={async () => {
                                this.setOrder(item);
                              }}
                            />
                          }
                        />
                      </View>
                    );
                  })}
                </ScrollView>
              </View>
            </Portal>
          )}
        </View>
        {(this.props.data ?? []).length > 0 ? (
          <View style={{ marginBottom: 60, zIndex: 889 }}>
            <ScrollView>
              {(this.props.data ?? []).map((item, key) => {
                const today = moment();
                const created = moment(item.dataCad ?? undefined);

                const dateData = {
                  years: Math.abs(
                    parseInt(today.format("yyyy")) -
                      parseInt(created.format("yyyy"))
                  ),
                  months: Math.abs(
                    parseInt(today.format("MM")) -
                      parseInt(created.format("MM"))
                  ),
                  days: Math.abs(
                    parseInt(moment().format("DD")) -
                      parseInt(
                        moment(moment().subtract(1, "month"))
                          .endOf("month")
                          .format("MM")
                      )
                  ),
                };

                return (
                  <View style={{ marginBottom: 10 }}>
                    <Card>
                      <CardContent>
                        <View
                          style={{
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                        >
                          <View
                            style={{
                              flexDirection: "column",
                              alignItems: "center",
                              justifyContent: "flex-start",
                            }}
                          >
                            <View style={{ width: "75%", marginTop: 25 }}>
                              {item?.urlFoto ? (
                                <img
                                  alt={item.nome}
                                  src={
                                    item?.urlFoto ??
                                    require("../../../assets/empty_image.png")
                                  }
                                  style={{
                                    flex: 1,
                                    width: "100%",
                                    height: "100%",
                                    borderRadius: "50%",
                                  }}
                                />
                              ) : undefined}
                            </View>
                            <View
                              style={{ paddingTop: 15, alignItems: "center" }}
                            >
                              <InputLabel
                                style={{
                                  fontSize: 22,
                                  color: Colors.black,
                                  fontWeight: "bold",
                                  marginBottom: 10,
                                }}
                              >
                                R$
                                {(item?.precoConsulta ?? "0.00").replace(
                                  ".",
                                  ","
                                )}
                              </InputLabel>
                              <InputLabel
                                style={{
                                  fontSize: 16,
                                  color: Colors.black,
                                  fontWeight: "bold",
                                }}
                              >
                                Por hora
                              </InputLabel>
                            </View>
                            <View
                              style={{
                                flexDirection: "row",
                                marginVertical: 20,
                              }}
                            >
                              {[...Array(Number.parseInt(3))].map((i1, k1) => {
                                //  console.log(k1);
                                return (
                                  <View>
                                    <Star color={Colors.primary} size={26} />
                                  </View>
                                );
                              })}
                            </View>
                            <View style={{ marginTop: 10, bottom: 25 }}>
                              <Link
                                style={{ textDecoration: "none" }}
                                to={{
                                  pathname: `/doutor`,
                                  state: item,
                                }}
                              >
                                <Button
                                  color={"primary"}
                                  variant={"contained"}
                                  style={{ marginTop: 15 }}
                                >
                                  Ver perfil completo
                                </Button>
                              </Link>
                            </View>
                          </View>
                          <View
                            style={{
                              marginStart: 36,
                              marginBottom: 10,
                              flex: 1,
                              flexDirection: "column",
                              justifyContent: "space-between",
                            }}
                          >
                            <View>
                              <View
                                style={{
                                  justifyContent: "flex-start",
                                  alignItems: "center",
                                  flexDirection: "row",
                                }}
                              >
                                <TouchableOpacity
                                  style={{
                                    justifyContent: "flex-start",
                                    alignItems: "center",
                                    flexDirection: "row",
                                  }}
                                  onPress={async () => {
                                    let session =
                                      localStorage.getItem("user_info");
                                    session = JSON.parse(session);
                                    console.log(item);
                                    let req = undefined;
                                    if (item?.favoritoPaciente == true) {
                                      req = await DeleteHandler(
                                        `pacientes/${session?.id}/favoritos/${item.favoritoId}`,
                                        {
                                          perfilId: session?.perfilId,
                                        }
                                      );
                                    } else {
                                      if (session?.id == undefined) {
                                        this.sendSchedule({
                                          ...item,
                                          favorito: true,
                                        });
                                        return;
                                      }
                                      req = await PostHandler(
                                        `pacientes/${session?.id}/favoritos?perfilId=${session?.perfilId}`,
                                        {
                                          idDoutor: item?.id ?? item?.doutorId,
                                        }
                                      );
                                    }
                                    if (req?.data?.success) {
                                      this.props.getData();
                                    }
                                  }}
                                >
                                  <View>
                                    {/*console.log('DOUTOR: ', this.props.data?.favoritoPaciente)*/}
                                  </View>
                                  <View>
                                    {item?.favoritoPaciente == false ? (
                                      <BookmarkBorderOutlined
                                        size={22}
                                        style={{
                                          color: Colors.secondary,
                                          marginTop: 15,
                                          marginEnd: 10,
                                        }}
                                      />
                                    ) : (
                                      <BookmarkSharp
                                        size={22}
                                        style={{
                                          color: Colors.secondary,
                                          marginTop: 15,
                                          marginEnd: 10,
                                        }}
                                      />
                                    )}
                                  </View>
                                </TouchableOpacity>
                                <View>
                                  <InputLabel
                                    style={{
                                      paddingStart: 10,
                                      fontSize: 18,
                                      marginBottom: 10,
                                      marginTop: 25,
                                    }}
                                  >
                                    {item?.nome}
                                  </InputLabel>
                                </View>
                              </View>

                              <InputLabel
                                style={{ fontSize: 14, marginBottom: 10 }}
                              >
                                {item?.conselho?.codigo
                                  ? item?.conselho?.codigo + ":"
                                  : ""}{" "}
                                {item?.conselho?.codCBO ?? ""}
                              </InputLabel>

                              <InputLabel
                                style={{
                                  marginBottom: 10,
                                  fontSize: 16,
                                  color: Colors.black,
                                  fontWeight: "bold",
                                }}
                              >
                                Sobre mim:
                              </InputLabel>
                              <InputLabel
                                style={{
                                  lineHeight: "1.5",
                                  fontSize: 14,
                                  color: Colors.black,
                                }}
                              >
                                {item?.sobre}
                              </InputLabel>
                            </View>
                            <View
                              style={{
                                flexDirection: "row",
                                flexWrap: "wrap",
                                justifyContent: "flex-between",
                                marginTop: 15,
                              }}
                            >
                              <View style={{ marginBottom: 15, marginEnd: 25 }}>
                                <InputLabel
                                  style={{
                                    color: Colors.secondary,
                                    fontSize: 18,
                                    lineHeight: "1.5",
                                  }}
                                >
                                  Tempo de Consulta
                                </InputLabel>
                                <InputLabel style={{ lineHeight: "1.5" }}>
                                  {" "}
                                  <span style={{ color: Colors.secondary }}>
                                    {" "}
                                    •{" "}
                                  </span>{" "}
                                  {this.props?.location?.tipoAtendimento == "video" ? item?.procedimentoPadrao?.duracaoVideoConsulta ?? 50 :item?.procedimentoPadrao?.duracao ?? 50} minutos 
                                </InputLabel>
                              </View>
                              <View style={{ marginBottom: 15, marginEnd: 25 }}>
                                <InputLabel
                                  style={{
                                    color: Colors.secondary,
                                    fontSize: 18,
                                    lineHeight: "1.5",
                                  }}
                                >
                                  Formação Acadêmica
                                </InputLabel>
                                {(item?.formacoes ?? []).map((form, ind) => {
                                  return (
                                    <View key={`formmacao-${ind}`}>
                                      <InputLabel style={{ lineHeight: "1.5" }}>
                                        {" "}
                                        <span
                                          style={{ color: Colors.secondary }}
                                        >
                                          {" "}
                                          •{" "}
                                        </span>{" "}
                                        {form?.nomeFormacao}{" "}
                                      </InputLabel>
                                      <InputLabel style={{ lineHeight: "1.5" }}>
                                        {" "}
                                        <span
                                          style={{ color: Colors.secondary }}
                                        >
                                          {" "}
                                          •{" "}
                                        </span>{" "}
                                        Periodo:{" "}
                                        {form?.periodoDe
                                          ? `(${moment(
                                              form?.periodoDe ?? undefined
                                            ).format("MM/yyyy")} - ${moment(
                                              form?.periodoAte ?? undefined
                                            ).format("MM/yyyy")})`
                                          : "Não Informado"}
                                      </InputLabel>
                                      <InputLabel style={{ lineHeight: "1.5" }}>
                                        {" "}
                                        <span
                                          style={{ color: Colors.secondary }}
                                        >
                                          {" "}
                                          •{" "}
                                        </span>{" "}
                                        {form?.instituicaoEnsino}
                                      </InputLabel>
                                    </View>
                                  );
                                })}
                              </View>

                              <View style={{ marginBottom: 15, marginEnd: 25 }}>
                                <InputLabel
                                  style={{
                                    color: Colors.secondary,
                                    fontSize: 18,
                                    lineHeight: "1.5",
                                  }}
                                >
                                  Atende
                                </InputLabel>
                                {(item?.gruposAtendimento ?? []).map(
                                  (at, ind) => {
                                    return (
                                      <InputLabel
                                        style={{ lineHeight: "1.5" }}
                                        key={`ga-${ind}`}
                                      >
                                        {" "}
                                        <span
                                          style={{ color: Colors.secondary }}
                                        >
                                          {" "}
                                          •{" "}
                                        </span>{" "}
                                        {at.nome}
                                      </InputLabel>
                                    );
                                  }
                                )}
                              </View>

                              <View style={{ marginBottom: 15, marginEnd: 25 }}>
                                <InputLabel
                                  style={{
                                    color: Colors.secondary,
                                    fontSize: 18,
                                    lineHeight: "1.5",
                                  }}
                                >
                                  Idiomas
                                </InputLabel>
                                {(item?.idiomas ?? []).map((at, ind) => {
                                  return (
                                    <InputLabel
                                      style={{ lineHeight: "1.5" }}
                                      key={`ga-${ind}`}
                                    >
                                      {" "}
                                      <span style={{ color: Colors.secondary }}>
                                        {" "}
                                        •{" "}
                                      </span>{" "}
                                      {at.nome}
                                    </InputLabel>
                                  );
                                })}
                              </View>
                            </View>

                            <View
                              style={{
                                flexDirection: "row",
                                justifyContent: "space-between",
                                width: 260,
                                marginTop: 15,
                                bottom: 5,
                              }}
                            >
                              <View>
                                <InputLabel style={{ color: Colors.black }}>
                                  {dateData.years ?? 0} Anos,{" "}
                                  {dateData.months ?? 0} Meses
                                </InputLabel>
                              </View>
                              <View>
                                <InputLabel style={{ color: Colors.black }}>
                                  {item.totalConsultasAtendidas ?? 0} Consultas
                                </InputLabel>
                              </View>
                            </View>
                          </View>
                          <View style={{ alignItems: "flex-end" }}>
                            <View style={{ flex: 1, width: "75%" }}>
                              <CalendarioCompleto
                                dados={item}
                                sendSchedule={async () => {
                                  this.sendSchedule(item);
                                }}
                                setDate={(data) => {
                                  const scheduleData = this.state.scheduleData;
                                  scheduleData.data = data.format("yyyy-MM-DD");
                                  this.setState({ scheduleData });
                                }}
                                setTime={(time) => {
                                  const scheduleData = this.state.scheduleData;
                                  scheduleData.horario = time;
                                  this.setState({ scheduleData });
                                }}
                                location={this.props.location}
                                type={this.props?.filter?.tipoAtendimento}
                              />
                            </View>
                          </View>
                        </View>
                      </CardContent>
                    </Card>
                  </View>
                );
                return (
                  <ProfissionalItems
                    profile={this.state.profile}
                    key={"profitem-" + key}
                    data={item}
                    setProfileData={(profile) => {
                      //console.log('PERFIL: ', profile);
                      this.setProfile(profile);
                    }}
                  />
                );
              })}
            </ScrollView>
            <View
              style={{
                flexDirection: "row",
                justifyContent: "center",
                padding: 5,
                margin: 5,
              }}
            >
              <View>
                {/*this.state.pageOptions?.page <
                  this.state.pageOptions.totalPages && (
                    <Button
                      mode={"InputLabel"}
                      onClick={() => {
                        console.log(this.state.pageOptions);
                        this.getData(this.state.pageOptions);
                      }}
                    >
                      Ver Mais
                    </Button>
                    )*/}
              </View>
            </View>
          </View>
        ) : (
          <View
            style={{
              flexDirection: "row",
              justifyContent: "center",
              padding: 5,
              margin: 5,
            }}
          >
            {!this.state?.refreshing ? (
              <View>
                <InputLabel
                  style={{
                    fontSize: 14,
                    color: Colors.black,
                  }}
                >
                  Não há resultados
                </InputLabel>
                <a
                  href="/"
                  style={{
                    marginTop: 10,
                    textDecoration: "none",
                    color: Colors.tertiary,
                  }}
                >
                  Voltar para a busca
                </a>
              </View>
            ) : undefined}
          </View>
        )}
      </View>
    );
  }
}
const mapStateToProps = (store) => ({
  session: store.authReducer.data,
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ setSnackbar, setProfileDialog, setTimer }, dispatch);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SearchList)
);

const styles = {
  container: {
    paddingTop: 30,
    borderRadius: 0,
    backgroundColor: Colors.primary,
    minHeight: "100%",
  },
  secView: {
    flex: 1,
    flexDirection: "row",
    alignItems: "flex-end",
    justifyContent: "flex-end",
    paddingRight: 15,
    maxHeight: 65,
    paddingBottom: 15,
    bottom: 0,
    backgroundColor: Colors.secondary,
    width: "100%",
  },
  logoContainer: {
    alignItems: "center",
    justifyContent: "center",
    padding: 30,
  },
  logo: {
    height: 150,
    width: 150,
    resizeMode: "contain",
  },
  inputDefaultStyle: {
    height: 40,
  },
};
