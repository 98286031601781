export class Credentials {
    password: '';
    email: String;
    perfil_id?: Number;
    //paciente: any = '1';
    constructor(credentials : {email: '', password: '', perfil_id?: Number}) {
        this.email = credentials.email;
        this.password = credentials.password;
        this.perfil_id = credentials.perfil_id;
    }
 
    toString(){
        return this.email + " " + this.password;
    }
}