import React, { Component } from "react";
import { ActivityIndicator, Text, View } from "react-native-web";
import {Button} from "@material-ui/core";
import moment from "moment";
import "moment/locale/pt-br";
import Colors from "../../../commons/Colors";
import { InputLabel } from "@material-ui/core";
import { TouchableHighlight } from "react-native";

export default class Calendar extends Component {
  state = {
    data: this.props?.data ?? [],
    currentTime: "00:00",
    selected: undefined,
  };
  componentDidMount() {
    console.log("DATA: ", this.props.data);
    //this.setState({ ...this.state, currentTime: moment().format("HH:mm") });
    /*setInterval(
      function () {
        this.setState({ ...this.state, currentTime: moment().format("HH:mm") });
      }.bind(this),
      60000
    );*/
  }

  render() {
    let horarios = this.props?.data.length > 0 ? this.props?.data[0]?.horariosList?.filter((item) => {
      let actualDate = parseInt(moment().format("HHmm"));
      if (
        this.props?.data[0]?.data == moment().format("yyyy-MM-DD")
      ) {
        if (parseInt(item.inicio.replace(":", "")) >= actualDate) {
          return item;
        }
      } else {
        return item;
      }
    }) : [];

    return (
      <>
        <View style={{ flexDirection: "row", justifyContent: "center" }}>
          <View
            style={{
              flex: 1,
              width: 376,
              flexDirection: "row",
              flexWrap: "wrap",
              justifyContent: "flex-start",
            }}
          >
            {this.props.refreshing == true ? (
              <View
                style={{
                  flex: 1,
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <ActivityIndicator color={Colors.primary} />
              </View>
            ) : horarios?.length == 0 ||
              this.props?.data[0]?.dataDisponivel == false ? (
              <View style={{...this.styles.notFoundContainer, flexWrap: "nowrap",}}>
                <InputLabel style={this.styles.notFoundText}>
                  {" "}
                  Não há horários disponíveis
                </InputLabel>
              </View>
            ) : (
              (horarios.length > 0
                ? horarios : [])
                ?.map((item, ind) => {
                  let disponivel = true;
                  console.log('PROPSS: ',this.props)
                  if (this.props.type == "presencial") {
                    disponivel = item.disponivel;
                  } else {
                    disponivel = this.props?.doutor?.possuiVideoconsulta == true ? item.disponivelVideo : false;
                  }

                  // return (<Text>{item.inicio+'H'}</Text>)
                  let backgroundColor = disponivel == false
                    ? "#a1a1a1"
                    : this.state.selected == item.inicio
                    ? Colors.primary
                    : Colors.white;

                  let color = !disponivel
                    ? Colors.black
                    : this.state.selected == item.inicio
                    ? Colors.white
                    : Colors.black;

                  return (
                    <View style={{ marginEnd: 5, marginBottom: 5 }} key={`time-btn-${ind}`}>
                      <Button
                        variant={"contained"}
                        disabled={!disponivel}
                        color={this.state.selected == item.inicio
                          ? "primary"
                          : "default"}
                        onClick={() => {
                          console.log(this.state.selected, item.inicio);
                        if (!disponivel) {
                          return false;
                        }
                        this.props.setTime(item.inicio);
                        this.setState({ ...this.state, selected: item.inicio });
                        }}
                      >
                       {item.inicio + "H"}
                      </Button>
                    </View>
                  );
                  return (
                    <TouchableHighlight
                      onClick={() => {
                        console.log(this.state.selected, item.inicio);
                        if (!disponivel) {
                          return false;
                        }
                        this.props.setTime(item.inicio);
                        this.setState({ ...this.state, selected: item.inicio });
                      }}
                    >
                      <View
                        style={{
                          borderRadius: 3,
                          backgroundColor,
                          color,
                          paddingVertical: 5,
                          paddingHorizontal: 10,
                          borderWidth: 1,
                          borderColor: "black",
                          marginTop: 5,
                          marginEnd: 5,
                        }}
                      >
                        <Text>{item.inicio + "H"}</Text>
                      </View>
                    </TouchableHighlight>
                  );
                  return (
                    <Button
                      style={{ margin: 1 }}
                      color={
                        !disponivel
                          ? "#a1a1a1"
                          : this.state.selected == item.inicio
                          ? Colors.primary
                          : Colors.white
                      }
                      key={"schedule-list-item-" + ind}
                      variant="contained"
                      onClick={() => {
                        if (!disponivel) {
                          return false;
                        }
                        this.props.setTime(item.inicio);
                        this.setState({ ...this.state, selected: item.inicio });
                      }}
                    >
                      {item.inicio + "H"}
                    </Button>
                  );
                })
            )}
          </View>
        </View>
      </>
    );
  }
  styles = {
    header: {
      flexDirection: "row",
      justifyContent: "space-around",
      alignItems: "center",
    },
    fab: {},

    item: {
      borderLeftWidth: 10,
      borderBottomWidth: 1,
      borderBottomColor: "#e3e3e3",
    },
    notFoundContainer: {
      padding: 5,
      justifyContent: "center",
      margin: 5,
    },
    notFoundText: {
      marginTop: 30,
      textAlign: "center",
    },
  };
}
