import React, { Component, Fragment } from "react";
import { View, ScrollView, Text } from "react-native-web";
//
import Colors from "../../../commons/Colors";
//
import { GetHandler, PostHandler } from "../../../handlers";
import {
  Avatar,
  Button,
  Card,
  CardContent,
  Checkbox,
  Chip,
  FormControlLabel,
  Icon,
  IconButton,
  InputLabel,
  Portal,
} from "@material-ui/core";
import { ArrowLeft, ArrowLeftSharp, CheckBox, Star } from "@material-ui/icons";
import CalendarHeader from "./calendarHeader";
import Calendar from "./calendar";
import moment from "moment";
import { Alert, Dimensions } from "react-native";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { setProfileDialog } from "../../../actions/appActions";
import ReactPlayer from "react-player/lazy";
import YoutubePlayer from "react-native-youtube-iframe";

function CalendarioCompleto(props: any) {
  const [reloading, setReloading] = React.useState(false);
  const [date, setDate] = React.useState(moment());
  const [schedules, setSchedules] = React.useState([]);
  const getSchedules = async (data, first = false) => {
    setReloading(true);
    const response = await GetHandler(
      `agenda/listaHorarios/${props.dados?.id ?? props.dados?.doutorId}`,
      { data, perfilId: props.dados?.perfilId },
      props?.navigation
    );
    const schedules = response?.data ?? [];

    setSchedules(schedules);
    setReloading(false);
    return schedules;
  };
  React.useEffect(() => {
    async function fetchData() {
      console.log(date.format("yyyy-MM-DD"));
      await getSchedules(date.format("yyyy-MM-DD"));
    }
    fetchData();
    // return true;
  }, []);

  return (
    <View>
      <CalendarHeader
        dataStart={date}
        getData={(dataStart) => {}}
        setDate={async (dataStart) => {
          props.setDate(dataStart);
          setDate(dataStart);
          //const scheduleData = date
          //scheduleData.data = dataStart.format("yyyy-MM-DD");
          await getSchedules(dataStart.format("yyyy-MM-DD"));
        }}
        month={true}
      />
      <ScrollView style={{ maxHeight: 250 }}>
        <Calendar
          refreshing={reloading}
          date={date}
          data={schedules}
          doutor={props.dados}
          type={props?.type}
          setTime={(time) => {
            //const scheduleData = this.state.scheduleData;
            //scheduleData.horario = time;
            props.setTime(time);
            // console.log(scheduleData);
          }}
        />
      </ScrollView>
      {schedules[0]?.dataDisponivel && (
        <Button
          fullWidth
          color={"primary"}
          variant={"contained"}
          style={{ marginTop: 15 }}
          onClick={async () => {
            //alert("clicou");
            await props.sendSchedule();
          }}
        >
          Agendar
        </Button>
      )}
    </View>
  );
}
class Profile extends Component {
  state = {
    dataStart: moment(),
    doutor: undefined,
    profile: undefined,
    doctorData: undefined,
    showOrderpanel: false,
    scheduleData: {
      pacienteId: undefined,
      perfilId: undefined,
      doutorId: this.props.dados?.id,
      horario: undefined,
      data: moment().format("yyyy-MM-DD"),
      tipoAtendimento: this.props?.location?.tipoAtendimento ?? "video",
      //linkPagSeguro: true,
      linkAsaas: true,
      formaPagAsaas: "",
    },
    orderBy: [
      { column: "pontuacao.desc", label: "Avaliação", checked: false },
      {
        column: "totalConsultasAtendidas.desc",
        label: "Número de consultas",
        checked: false,
      },
      { column: "precoConsulta.desc", label: "Maior Preço", checked: false },
      { column: "precoConsulta.asc", label: "Menor Preço", checked: false },
      { column: "pontuacao.desc", label: "Tempo de trabalho", checked: false },
    ],
    results: [],
    pageOptions: undefined,
    refreshing: false,
  };

  async setOrder(item) {
    let orderBy = this.state.orderBy;
    orderBy.find((order) => order.label == item.label).checked = !item.checked;
    this.setState({
      orderBy,
      showOrderpanel: false,
    });
    await this.props.getData(undefined, orderBy);
  }

  getDoctor = async () => {
    try {
      let request = await GetHandler(
        `doutores`,
        {
          doutorId: this.props.location?.state?.id,
          totalConsultasAtendidas: true
        },
        this.props.navigation
      );
      this.setState({
        doutor:
          (request.data?.results ?? []).length > 0
            ? request.data?.results[0]
            : undefined,
      });
      console.log("DOUTOR: ", this.props.location, this.state?.doutor);
    } catch (e) {
      console.error(e);
    }
  };

  sendSchedule = async (item: any) => {
    let authData = JSON.parse(localStorage.getItem("user_info"));

    let scheduleData = this.state.scheduleData;
    scheduleData.doutorId = item?.id ?? item?.doutorId;
    scheduleData.perfilId = item?.perfilId;
    scheduleData.pacienteId = authData?.id;
    scheduleData.paciente = {
      nome: authData?.nome ? `${authData?.nome} ${authData?.sobrenome}` : "",
      cpf: authData?.cpf ?? "",
      email: authData?.email ?? "",
    };

    this.setState({ ...this.state, reloading: true });
    console.log("PP: ", scheduleData, item);
    if (scheduleData.horario === undefined) {
      this.props.setProfileDialog({
        title: "Selecione um horário",
        body: "\n\nÉ necessário escolher um horário disponível para agendar a consulta?",
        open: true,
        singleButton: true,
        type: "yesno",
        onSave: undefined,
        onCancel: () => {},
      });

      this.setState({ ...this.state, reloading: false });
      return false;
    }
    /*if (!this.state.terms) {
      Alert.alert(
        "Termos e condições",
        " é necessário concordar com os termos e condições de agendamento"
      );
      this.setState({ ...this.state, reloading: false });
      return false;
    }*/
    this.setState({ ...this.state, reloading: true });
    if (
      !scheduleData.pacienteId ||
      !scheduleData.perfilId ||
      !scheduleData.doutorId
    ) {
      this.props.setProfileDialog({
        title: "Login Obrigatório",
        body: "\n\nFaça login para continuar?",
        open: true,
        singleButton: true,
        type: "yesno",
        onSave: () => {
          this.props.history.push({
            pathname: "/login",
            state: {
              data: scheduleData,
            },
          });
        },
        onCancel: () => {},
      });

      //window.location.href = "/login"
      /*this.props.navigation.navigate("profile", {
        refresh: true,
        schedule: scheduleData,
      });*/
    } else {
      if ((authData?.cpf ?? "").length <= 10) {
        this.props.setProfileDialog({ open: true, type: "profile" });
        return false;
      }
      const scheduling = await PostHandler(
        `consultas`,
        scheduleData,
        undefined
      );

      if (scheduling?.data?.success) {
        let body =
          "\n\nO seu horário foi reservado, e será confirmado após efetuar o pagamento !";
        body += "\n Deseja ser redirecionado para pagamento da sessão agora ?";
        this.props.setProfileDialog({
          title: "Atenção",
          body,
          open: true,
          type: "yesno",
          onSave: () => {
            window.open(
              scheduling?.data?.data?.linkCobranca?.linkCobranca,
              "_blank"
            );
            window.location.href = "/history";
          },
          onCancel: () => {
            window.location.href = "/history";
          },
        });
      } else {
        //console.log('AGAGAG:', scheduling)
        this.props.setProfileDialog({
          title: "Problema ao agendar",
          body:
            scheduling?.data?.message ??
            "Houve um problema ao tentar efetuar o agendamento",
          open: true,
          singleButton: true,
          type: "yesno",
          onSave: undefined,
          onCancel: () => {},
        });
      }
    }
    this.setState({ ...this.state, reloading: false });
  };

  async componentDidMount() {
    await this.getDoctor();
  }
  render() {
    //this.getDoctor();
    let item = this.state.doutor ?? this.props.location.state;
    const today = moment();
    const created = moment(item.dataCad ?? undefined);

    const dateData = {
      years: Math.abs(
        parseInt(today.format("yyyy")) - parseInt(created.format("yyyy"))
      ),
      months: Math.abs(
        parseInt(today.format("MM")) - parseInt(created.format("MM"))
      ),
      days: Math.abs(
        parseInt(moment().format("DD")) -
          parseInt(
            moment(moment().subtract(1, "month")).endOf("month").format("MM")
          )
      ),
    };

    return (
      <View>
        <View
          style={{
            // backgroundColor: Colors.primary,
            padding: 15,
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <View>
            <InputLabel style={{ color: Colors.primary }}>
              Perfil do Doutor
            </InputLabel>
          </View>
          <View>
            <IconButton
              onClick={() => {
                window.location.href = "/";
              }}
              color="primary"
              aria-label="upload picture"
              component="label"
            >
              <ArrowLeftSharp />
            </IconButton>
          </View>
        </View>
        <View style={{ marginBottom: 60, zIndex: 889 }}>
          <ScrollView>
            <View style={{ marginBottom: 10 }}>
              <Card>
                <CardContent>
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <View
                      style={{
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "flex-start",
                      }}
                    >
                      <View style={{ width: "75%" }}>
                        {item?.urlFoto ? (
                          <img
                            alt={item.nome}
                            src={
                              item?.urlFoto ??
                              require("../../../../assets/empty_image.png")
                            }
                            style={{
                              flex: 1,
                              width: "100%",
                              height: "100%",
                              borderRadius: "50%",
                            }}
                          />
                        ) : undefined}
                      </View>
                      <View style={{ paddingTop: 15, alignItems: "center" }}>
                        <InputLabel
                          style={{
                            fontSize: 22,
                            color: Colors.black,
                            fontWeight: "bold",
                            marginBottom: 10,
                          }}
                        >
                          R$
                          {(item?.precoConsulta ?? "0.00").replace(".", ",")}
                        </InputLabel>
                        <InputLabel
                          style={{
                            fontSize: 16,
                            color: Colors.black,
                            fontWeight: "bold",
                            marginBottom: 10,
                          }}
                        >
                          Por hora
                        </InputLabel>
                      </View>
                      <View style={{ flexDirection: "row" }}>
                        {[...Array(Number.parseInt(3))].map((i1, k1) => {
                          //  console.log(k1);
                          return (
                            <View>
                              <Star color={Colors.primary} size={26} />
                            </View>
                          );
                        })}
                      </View>
                      <View style={{ marginTop: 10, bottom: 25 }}></View>
                    </View>
                    <View
                      style={{
                        marginStart: 36,
                        marginBottom: 10,
                        flex: 1,
                        flexDirection: "column",
                        justifyContent: "space-between",
                      }}
                    >
                      <View>
                        <InputLabel
                          style={{
                            fontSize: 22,
                            marginBottom: 10,
                            marginTop: 25,
                          }}
                        >
                          {item?.nome}
                        </InputLabel>

                        <InputLabel style={{ fontSize: 14, marginBottom: 10 }}>
                          {item?.conselho?.codigo
                            ? item?.conselho?.codigo + ":"
                            : ""}{" "}
                          {item?.conselho?.codCBO ?? ""}
                        </InputLabel>

                        <View
                          style={{
                            flexDirection: "row",
                            flexWrap: "wrap",
                            marginVertical: 10,
                          }}
                        >
                          <ScrollView
                            horizontal={true}
                            style={{ paddingVertical: 5 }}
                          >
                            {(item?.tagsTratamentos ?? []).length > 0 && (item?.tagsTratamentos ?? []).map((cns, ind) => {
                              console.log('CHIP: ',cns,ind)
                              return <Chip
                                  key={`tagsTrat-${ind}`}
                                  mode={"outlined"}
                                  label={cns}
                                  style={{ marginHorizontal: 5 }}
                                  onPress={() => console.log(cns,ind)}
                                >
                                  TEXTO TESTE {cns} {ind}
                                </Chip>
                            })}
                          </ScrollView>
                        </View>
                        {item?.linkVideoProfissional?.length > 5 ? (
                        <View>
                          <ReactPlayer
                            playing={true}
                            width='100%'
                            height={350}
                            url={item?.linkVideoProfissional}
                          />
                        </View>)
                         : undefined
                        }
                        <View style={{ marginVertical: 10 }}></View>

                        <View style={{ marginVertical: 5 }}>
                          <InputLabel
                            style={{
                              fontSize: 18,
                              color: Colors.black,
                              fontWeight: "bold",
                            }}
                          >
                            Sobre mim:
                          </InputLabel>
                        </View>
                        <InputLabel
                          style={{
                            lineHeight: "1.5",
                            fontSize: 14,
                            color: Colors.black,
                          }}
                        >
                          {item?.sobre}
                        </InputLabel>
                      </View>

                      <View
                        style={{
                          flexDirection: "row",
                          justifyContent: "space-between",
                          width: 260,
                          marginTop: 15,
                          bottom: 5,
                        }}
                      >
                        <View>
                          <InputLabel style={{ color: Colors.black }}>
                            {dateData.years ?? 0} Anos, {dateData.months ?? 0}{" "}
                            Meses
                          </InputLabel>
                        </View>
                        <View>
                          <InputLabel style={{ color: Colors.black }}>
                            {item.totalConsultasAtendidas ?? 0} Consultas
                          </InputLabel>
                        </View>
                      </View>
                      <View
                        style={{
                          flexDirection: "row",
                          flexWrap: "wrap",
                          justifyContent: "flex-between",
                          marginTop: 15,
                        }}
                      >
                        <View style={{ marginBottom: 15, marginEnd: 25 }}>
                          <Text
                            style={{
                              color: Colors.secondary,
                              fontSize: 18,
                              lineHeight: "1.5",
                            }}
                          >
                            Tempo de Consulta
                          </Text>
                          <Text style={{ lineHeight: "1.5" }}>
                            <span style={{ color: Colors.secondary }}> • </span>{" "}
                            {this.props?.location?.tipoAtendimento == "video" ? item?.procedimentoPadrao?.duracaoVideoConsulta ?? 50 :item?.procedimentoPadrao?.duracao ?? 50} minutos 
                          </Text>
                        </View>
                        <View style={{ marginBottom: 15, marginEnd: 25 }}>
                          <Text
                            style={{
                              color: Colors.secondary,
                              fontSize: 18,
                              lineHeight: "1.5",
                            }}
                          >
                            Formação Acadêmica
                          </Text>
                          {(item?.formacoes ?? []).map((form, ind) => {
                            return (
                              <View key={`formmacao-${ind}`}>
                                <Text style={{ lineHeight: "1.5" }}>
                                  <span style={{ color: Colors.secondary }}>
                                    {" "}
                                    •{" "}
                                  </span>{" "}
                                  {form?.nomeFormacao}{" "}
                                </Text>
                                <Text style={{ lineHeight: "1.5" }}>
                                  <span style={{ color: Colors.secondary }}>
                                    {" "}
                                    •{" "}
                                  </span>{" "}
                                  Periodo:{" "}
                                  {form?.periodoDe
                                    ? `(${moment(
                                        form?.periodoDe ?? undefined
                                      ).format("MM/yyyy")} - ${moment(
                                        form?.periodoAte ?? undefined
                                      ).format("MM/yyyy")})`
                                    : "Não Informado"}
                                </Text>
                                <Text style={{ lineHeight: "1.5" }}>
                                  <span style={{ color: Colors.secondary }}>
                                    {" "}
                                    •{" "}
                                  </span>{" "}
                                  {form?.instituicaoEnsino}
                                </Text>
                              </View>
                            );
                          })}
                        </View>

                        <View style={{ marginBottom: 15, marginEnd: 25 }}>
                          <Text
                            style={{
                              color: Colors.secondary,
                              fontSize: 18,
                              lineHeight: "1.5",
                            }}
                          >
                            Atende
                          </Text>
                          {(item?.gruposAtendimento ?? []).map((at, ind) => {
                            return (
                              <Text
                                key={`ga-${ind}`}
                                style={{ lineHeight: "1.5" }}
                              >
                                <span style={{ color: Colors.secondary }}>
                                  {" "}
                                  •{" "}
                                </span>{" "}
                                {at.nome}
                              </Text>
                            );
                          })}
                        </View>

                        <View style={{ marginBottom: 15, marginEnd: 25 }}>
                          <Text
                            style={{
                              color: Colors.secondary,
                              fontSize: 18,
                              lineHeight: "1.5",
                            }}
                          >
                            Idiomas
                          </Text>
                          {(item?.idiomas ?? []).map((at, ind) => {
                            return (
                              <Text
                                style={{ lineHeight: "1.5" }}
                                key={`ga-${ind}`}
                              >
                                <span style={{ color: Colors.secondary }}>
                                  {" "}
                                  •{" "}
                                </span>{" "}
                                {at.nome}
                              </Text>
                            );
                          })}
                        </View>
                      </View>
                    </View>
                    <View style={{ alignItems: "flex-end" }}>
                      <View style={{ flex: 1, width: "75%" }}>
                        <CalendarioCompleto
                          dados={item}
                          sendSchedule={async () => {
                            this.sendSchedule(item);
                          }}
                          setDate={(data) => {
                            const scheduleData = this.state.scheduleData;
                            scheduleData.data = data.format("yyyy-MM-DD");
                            this.setState({ scheduleData });
                          }}
                          setTime={(time) => {
                            const scheduleData = this.state.scheduleData;
                            scheduleData.horario = time;
                            this.setState({ scheduleData });
                          }}
                          location={this.props.location}
                          type={"online"}
                        />
                      </View>
                    </View>
                  </View>
                </CardContent>
              </Card>
            </View>
          </ScrollView>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "center",
              padding: 5,
              margin: 5,
            }}
          >
            <View>
              {/*this.state.pageOptions?.page <
                  this.state.pageOptions.totalPages && (
                    <Button
                      mode={"InputLabel"}
                      onClick={() => {
                        console.log(this.state.pageOptions);
                        this.getData(this.state.pageOptions);
                      }}
                    >
                      Ver Mais
                    </Button>
                    )*/}
            </View>
          </View>
        </View>
      </View>
    );
  }
}
const mapStateToProps = (store) => ({
  session: store.authReducer.data,
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ setProfileDialog }, dispatch);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Profile)
);

const styles = {
  container: {
    paddingTop: 30,
    borderRadius: 0,
    backgroundColor: Colors.primary,
    minHeight: "100%",
  },
  secView: {
    flex: 1,
    flexDirection: "row",
    alignItems: "flex-end",
    justifyContent: "flex-end",
    paddingRight: 15,
    maxHeight: 65,
    paddingBottom: 15,
    bottom: 0,
    backgroundColor: Colors.secondary,
    width: "100%",
  },
  logoContainer: {
    alignItems: "center",
    justifyContent: "center",
    padding: 30,
  },
  logo: {
    height: 150,
    width: 150,
    resizeMode: "contain",
  },
  inputDefaultStyle: {
    height: 40,
  },
};
